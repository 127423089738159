import { fabric } from 'fabric';
import { fabricPathToSVG } from '../Components/Tools/paintTools';

export const getTransformedPoint = (point, fabricObject,canvas) => {
  const x = point.x - fabricObject.pathOffset.x;
  const y = point.y - fabricObject.pathOffset.y;
  return fabric.util.transformPoint(
    { x, y },
    fabric.util.multiplyTransformMatrices(canvas.viewportTransform, fabricObject.calcTransformMatrix())
  );
};


export function drawDashedLine(canvas, point1, point2, lineObj = null) {
  if (lineObj) {
    lineObj.set({
      x1: point1.x,
      y1: point1.y,
      x2: point2.x,
      y2: point2.y,
    });
    canvas.requestRenderAll();
    return lineObj;
  }
  console.log('drawLine: ', canvas, point1, point2, lineObj)

  const line = new fabric.Line([point1.x, point1.y, point2.x, point2.y], {
    stroke: 'black',
    strokeWidth: 2,
    strokeDashArray: [5, 5], 
    selectable: false,
    evented: false,
  });

  canvas.add(line);
  return line;
}

export function createBezierControl(canvas, path, pointIndex, position, cmdIndex, lineControls = null) {
  const control = new fabric.Control({
    cornerSize: 10,
    fill: 'violet',
    selectable: true,
    left: position.x,
    top: position.y,
    pointIndex: pointIndex,
    cmdIndex: cmdIndex,
    lineControls: lineControls,
    render(ctx, left, top) {
      const size = this.cornerSize;
      ctx.save();
      ctx.fillStyle = this.fill;
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.arc(left, top, size / 2, 0, 2 * Math.PI, false);
      ctx.fill();
      ctx.stroke();
      ctx.restore();
    },
    positionHandler(dim, finalMatrix, fabricObject) {
      const x = fabricObject.path[this.pointIndex][this.cmdIndex] - fabricObject.pathOffset.x;
      const y = fabricObject.path[this.pointIndex][this.cmdIndex + 1] - fabricObject.pathOffset.y;

      // Transform the control point to absolute canvas space
      const transformedPoint = fabric.util.transformPoint(
        { x, y },
        fabric.util.multiplyTransformMatrices(fabricObject.canvas.viewportTransform, fabricObject.calcTransformMatrix())
      );

      // Save absolute positions for future use
      this.absoluteX = transformedPoint.x;
      this.absoluteY = transformedPoint.y;

      return transformedPoint;
    },
    actionHandler(eventData, transform, x, y) {
      const polygon = transform.target;
      const pointer = polygon.canvas.getPointer(eventData.e);

      // Update the path with the new control point position
      polygon.path[pointIndex][cmdIndex] = pointer.x;
      polygon.path[pointIndex][cmdIndex + 1] = pointer.y;

      // Update the line positions to stay connected to the control points
      if (lineControls && lineControls.line) {
        drawDashedLine(polygon.canvas, lineControls.control1, lineControls.control2, lineControls.line);
      }

      recalculateBoundingBox(polygon);
      polygon.dirty = true;
      polygon.canvas.requestRenderAll();
      return true;
    },
  });

  path.controls['p' + pointIndex + '-' + cmdIndex] = control;
  return control;
}


export const createPointControls = (canvas, path, setActiveNodes, activeNodes) => {
  if (!path || !path.path) {
    console.error('Path object is missing or invalid.');
    return;
  }

  const pathData = path.path.map(([cmd, ...points]) => {
    if (cmd === 'M' || cmd === 'L') {
      return { x: points[0], y: points[1] };
    } else if (cmd === 'C' || cmd === 'c') {
      return {
        control1: { x: points[0], y: points[1] },
        control2: { x: points[2], y: points[3] },
        end: { x: points[4], y: points[5] },
      };
    }
    return { x: 0, y: 0 };
  });

  path.edit = true;
  path.controls = {};
  path.curveControls = {};
  let prevEndControl = null;
  let prevControl2 = null; 
  let firstCurve = null;
  path.path.forEach((point, index) => {
    if (point[0] === 'C' || point[0] === 'c') {
      const control1Pos = pathData[index].control1;
      const control2Pos = pathData[index].control2;
      const endPos = pathData[index].end;

      const control1 = createBezierControl(canvas, path, index, control1Pos, 1);
      const control2 = createBezierControl(canvas, path, index, control2Pos, 3);
      const endControl = createBezierControl(canvas, path, index, endPos, 5);

      console.log('createCPoint: Control Points:', { control1, control2, endControl });

      if (control1 && control2 && endControl) {
        let mainLine1 = null;
        let mainLine2 = null;

        if (prevEndControl && prevControl2) {
          const globalPrevControl2 = getTransformedPoint(prevControl2, path,canvas);
          const globalPrevEndControl = getTransformedPoint(prevEndControl, path,canvas);
          const globalControl1 = getTransformedPoint(control1Pos, path,canvas);

          mainLine1 = drawDashedLine(canvas, globalPrevControl2, globalPrevEndControl);
          mainLine2 = drawDashedLine(canvas, globalPrevEndControl, globalControl1);
        }

        console.log('createCPoint: Lines:', { mainLine1, mainLine2 });

        prevEndControl = endControl;
        prevControl2 = control2;
        path.curveControls[index] = {
          control1,
          control2,
          endControl,
          lines: { mainLine1, mainLine1 },
        };
        if (!firstCurve) {
          firstCurve = {
            control1,
            control2,
            endControl,
          };
        }
        console.log("createCPoint: pat.curveControls, path.curveControls[index]:",path.curveControls, path.curveControls[index] )
      } else {
        console.warn('One or more control points are undefined:', { control1, control2, endControl });
      }
    }
 
    else if (point[0] !== 'Z' || point[0] !== 'z') {
      const cp = new fabric.Control({
        actionName: 'modifyPolygon',
        pointIndex: index,
        cornerSize: 10,
        fill: 'red', // Default fill color
        selectable: true,
        point: point,
        evented: true,
        hoverCursor: 'pointer',
        left: pathData[index].x,
        top: pathData[index].y,
        render(ctx, left, top, styleOverride, fabricObject) {
          const size = this.cornerSize;
          ctx.save();
          ctx.fillStyle = this.fill; // Set the fill color for the control point
          ctx.strokeStyle = 'black'; // Set the stroke color if desired
          ctx.lineWidth = 1; // Stroke width for the border
      
          // Draw the control as a circle
          ctx.beginPath();
          ctx.arc(left, top, size / 2, 0, 2 * Math.PI, false);
          ctx.fill();
          ctx.stroke();
          ctx.restore();
        },
        positionHandler(dim, finalMatrix, fabricObject) {
          if (!fabricObject?.path || !fabricObject?.path[this.pointIndex]) {
            console.warn('Path or path point is undefined.');
            return { x: 0, y: 0 };
          }

          const x = fabricObject.path[this.pointIndex][1] - fabricObject.pathOffset.x;
          const y = fabricObject.path[this.pointIndex][2] - fabricObject.pathOffset.y;
          const absolutePosition = fabric.util.transformPoint(
            { x, y },
            fabric.util.multiplyTransformMatrices(fabricObject.canvas.viewportTransform, fabricObject.calcTransformMatrix())
          );

          // Save absolute position on the control object itself
          this.absoluteX = absolutePosition.x;
          this.absoluteY = absolutePosition.y;

          return absolutePosition;
        },
        actionHandler(eventData, transform, x, y) {
          const polygon = transform.target;
          const currentControl = polygon.controls[polygon.__corner];

          if (!polygon.path || !polygon.path[currentControl.pointIndex]) {
            console.warn('Path or path point is undefined during action.');
            return;
          }

          const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
          const polygonBaseSize = getObjectSizeWithStroke(polygon);
          const size = polygon._getTransformedDimensions(0, 0);
          const moveFactor = 5;

          const finalPointPosition = {
            x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
            y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y,
          };

          polygon.path[currentControl.pointIndex][1] = finalPointPosition.x;
          polygon.path[currentControl.pointIndex][2] = finalPointPosition.y;

          recalculateBoundingBox(polygon);
          polygon.dirty = true;
          polygon.canvas.requestRenderAll();
          return true;
        },
      });

      // Initialize control with absolute position on creation
      cp.absoluteX = pathData[index].x;
      cp.absoluteY = pathData[index].y;

      path.controls['p' + index] = cp;
      path.selectable = true;
    }
  });

  let distance = (p1, p2) =>
      Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  // Function to check if a point lies on the line between two control points
  const isPointOnLine = (p1, p2, clickPoint, tolerance = 5) => {
    console.log('isPointOnLine: ', p1, p2, clickPoint);
    
    const d1 = distance({ x: p1.x / 0.7, y: p1.y / 0.7 }, clickPoint);
    const d2 = distance({ x: p2.x / 0.7, y: p2.y / 0.7 }, clickPoint);
    const lineLength = distance({ x: p1.x / 0.7, y: p1.y / 0.7 }, { x: p2.x / 0.7, y: p2.y / 0.7 });
    console.log('isPointOnLine: d1,d2,lineLength:', d1, d2, lineLength);
    console.log('isPointOnLine: return:', Math.abs(d1 + d2 - lineLength) <= tolerance);
    return Math.abs(d1 + d2 - lineLength) <= tolerance;
  };

  const isPointOnControl = (p1, clickPoint, tolerance = 5) =>{
    let cp = {x:p1.x/0.7, y:p1.y/0.7}
    const distance1 = distance(cp, clickPoint);
    console.log('isPointOnControl: ', cp, clickPoint,distance1);

    if (distance1 <= tolerance){
      return true;
    }else{
      return false;
    }

  }
  let lastLine1;
  let lastLine2;
  console.log('createCPoint: firstCurve: ', firstCurve);

  if (prevEndControl && firstCurve && firstCurve.control1) {

    const globalPrevControl2 = getTransformedPoint(prevControl2, path,canvas);
    const globalPrevEndControl = getTransformedPoint(prevEndControl, path,canvas);
    const globalFirstControl1 = getTransformedPoint(firstCurve.control1, path,canvas);

    const lastLine1 = drawDashedLine(canvas, globalPrevControl2, globalPrevEndControl);
    const lastLine2 = drawDashedLine(canvas, globalPrevEndControl, globalFirstControl1);

    path.curveControls[path.curveControls.length-1] = {
      control1:firstCurve.control1,
      control2:prevControl2,
      endControl:prevEndControl,
      lines: { lastLine1, lastLine2 },
    };
    console.log('createCPoint: Last Lines: ', lastLine1, lastLine2);
  }
  canvas.on('object:moving', (opt) => {
    if (!path.edit) return;

    const movingObject = opt.target;

    // Update the lines dynamically during movement
    Object.keys(path.curveControls).forEach((key) => {
      const controlSet = path.curveControls[key];
      const globalControl1 = getTransformedPoint(controlSet.control1, path,canvas);
      const globalControl2 = getTransformedPoint(controlSet.control2, path,canvas);
      const globalEndControl = getTransformedPoint(controlSet.endControl, path,canvas);

      if (controlSet.lines.mainLine1) {
        drawDashedLine(canvas, globalControl2, globalEndControl, controlSet.lines.mainLine1);
      }
      if (controlSet.lines.mainLine2) {
        drawDashedLine(canvas, globalEndControl, globalControl1, controlSet.lines.mainLine2);
      }
    });

    // Redraw the canvas after movement
    canvas.requestRenderAll();
  });



  canvas.on('mouse:down', (opt) => {
    if (!path.edit) return; // Only do this in edit mode
    const pointer = canvas.getPointer(opt.e);
    const clickPoint = { x: pointer.x, y: pointer.y };
    console.log(`createCustomPoints: mouse:down: clickPoint:`, clickPoint);

    // Object.values(path.controls).forEach(control => {
    //   control.fill = 'red';
    // });

    let foundControl = false;
    let foundVertex = false;

    const controlKeys = Object.keys(path.controls);

    controlKeys.forEach((key, index, arr) => {
      const control = path.controls[key];
      console.log('isPointOnControl: before if: ',index, arr, control, clickPoint)
      if (isPointOnControl({ x: control.absoluteX, y: control.absoluteY }, clickPoint)) {
        console.log('isPointOnControl: true',index, control, setActiveNodes)
        control.fill = 'blue';
        foundControl = true;
        if(setActiveNodes){

        setActiveNodes([control]); 
        }
        return; 
      }
    })

    if (!foundControl) {
      const controlKeys = Object.keys(path.controls);
      controlKeys.forEach((key, index, arr) => {
        if (index < arr.length - 1) {
          const control1 = path.controls[key];
          const control2 = path.controls[arr[index + 1]];
          if (isPointOnLine({ x: control1.absoluteX, y: control1.absoluteY }, { x: control2.absoluteX, y: control2.absoluteY }, clickPoint)) {
            control1.fill = 'green';
            control2.fill = 'green';
            foundVertex = true;
            console.log('isPointOnLine: true',index, setActiveNodes)

            if(setActiveNodes){
              setActiveNodes([control1, control2]); 
            }

          }
      }
    })

      const firstControl = path.controls[controlKeys[0]];
      const lastControl = path.controls[controlKeys[controlKeys.length - 1]];
      if (!foundVertex && isPointOnLine({ x: firstControl.absoluteX, y: firstControl.absoluteY }, { x: lastControl.absoluteX, y: lastControl.absoluteY }, clickPoint)) {
        firstControl.fill = 'green';
        lastControl.fill = 'green';
        foundVertex = true;
        console.log('isPointOnLine: last line: true',setActiveNodes);
        if(setActiveNodes){

        setActiveNodes([firstControl, lastControl]);
        }
      }
      return;
    }


    if (foundControl || foundVertex) {
      canvas.requestRenderAll(); 
    }
  });
  console.log("createPointControls: path string: ", fabricPathToSVG(path))
  canvas.setActiveObject(path);
  canvas.requestRenderAll();
};



export function recalculateBoundingBox(polygon) {
  const xValues = [];
  const yValues = [];

  polygon.path.forEach(point => {
    // Skip 'Z' commands
    if (point[0] !== 'Z' && point[0] !== 'z') { 
      if (point[0] === 'C' || point[0] === 'c') {
        // For 'C' or 'c' commands, we add all control points (x, y)
        xValues.push(point[1], point[3], point[5]);  // x1, x2, xEnd
        yValues.push(point[2], point[4], point[6]);  // y1, y2, yEnd
      } else {
        // For regular commands, add the endpoint (x, y)
        xValues.push(point[1]);
        yValues.push(point[2]);
      }
    }
  });

  // Calculate minimum and maximum values
  const minX = Math.min(...xValues);
  const maxX = Math.max(...xValues);
  const minY = Math.min(...yValues);
  const maxY = Math.max(...yValues);

  // Update the polygon's width, height, and pathOffset based on new bounds
  polygon.set({
    width: maxX - minX,
    height: maxY - minY,
    pathOffset: {
      x: minX + (maxX - minX) / 2,
      y: minY + (maxY - minY) / 2,
    }
  });

  // Update the object's coordinates on the canvas
  polygon.setCoords();
}

export const removeCustomControls = (canvas, path) => {
  if (path.edit) {
    console.log("removeCustomControls: Removing custom controls for path:", path);

    path.controls = fabric.Object.prototype.controls;

    path.edit = false;

    path.setControlsVisibility({
      ml: true, mt: true, mr: true, mb: true, 
      bl: true, br: true, tl: true, tr: true  
    });

    path.set({ selectable: true });

    canvas.setActiveObject(path);

    canvas.requestRenderAll();
  } else {
    console.log("removeCustomControls: No custom controls found for path.");
  }
};


export const createEllipseControls = (canvas, ellipse) => {
  const { left, top, rx, ry } = ellipse;
  const angle = ellipse.angle || 0;

  ellipse.controls = {};

  // Control point for top-left
  const cpTopLeft = new fabric.Control({
    positionHandler: function (dim, finalMatrix, fabricObject) {
      const transform = fabricObject.calcTransformMatrix();
      const center = fabricObject.getCenterPoint();
      const p = fabric.util.transformPoint(
        { x: -rx, y: -ry },
        fabric.util.multiplyTransformMatrices(finalMatrix, transform)
      );
      return fabric.util.transformPoint(p, fabric.util.invertTransform(finalMatrix));
    },
    actionHandler: function (eventData, transform, x, y) {
      const ellipse = transform.target;
      const finalPoint = new fabric.Point(x, y);
      const center = ellipse.getCenterPoint();
      const scaleX = (finalPoint.x - center.x) / (rx * 2);
      const scaleY = (finalPoint.y - center.y) / (ry * 2);
      ellipse.set({
        scaleX: scaleX,
        scaleY: scaleY,
      });
      canvas.renderAll();
      return true;
    },
    actionName: 'scale',
    cornerSize: 12,
    cornerColor: 'red',
    originX: 'center',
    originY: 'center',
    transparentCorners: false,
    lockRotation: true,
  });

  ellipse.controls.topLeft = cpTopLeft;

  // Control point for bottom-right
  const cpBottomRight = new fabric.Control({
    positionHandler: function (dim, finalMatrix, fabricObject) {
      const transform = fabricObject.calcTransformMatrix();
      const center = fabricObject.getCenterPoint();
      const p = fabric.util.transformPoint(
        { x: rx, y: ry },
        fabric.util.multiplyTransformMatrices(finalMatrix, transform)
      );
      return fabric.util.transformPoint(p, fabric.util.invertTransform(finalMatrix));
    },
    actionHandler: function (eventData, transform, x, y) {
      const ellipse = transform.target;
      const finalPoint = new fabric.Point(x, y);
      const center = ellipse.getCenterPoint();
      const scaleX = (finalPoint.x - center.x) / (rx * 2);
      const scaleY = (finalPoint.y - center.y) / (ry * 2);
      ellipse.set({
        scaleX: scaleX,
        scaleY: scaleY,
      });
      canvas.renderAll();
      return true;
    },
    actionName: 'scale',
    cornerSize: 12,
    cornerColor: 'red',
    originX: 'center',
    originY: 'center',
    transparentCorners: false,
    lockRotation: true,
  });

  ellipse.controls.bottomRight = cpBottomRight;

  // Add controls to ellipse
  ellipse.setControlsVisibility({
    tl: true,
    br: true,
    ml: false,
    mt: false,
    mr: false,
    mb: false,
    mtr: false,
  });

  canvas.renderAll();
};


export const getObjectSizeWithStroke = object => {
  const stroke = new fabric.Point(
    object.strokeUniform ? 1 / object.scaleX : 1,
    object.strokeUniform ? 1 / object.scaleY : 1
  ).multiply(object.strokeWidth);
  return new fabric.Point(object.width + stroke.x, object.height + stroke.y);
};


function getAdjacentPoints(path, currentIndex, count) {
  const totalPoints = path.length;
  let indices = [];

  for (let i = 1; i <= count; i++) {
    const prevIndex = (currentIndex - i + totalPoints) % totalPoints;
    const nextIndex = (currentIndex + i) % totalPoints;
    indices.push(prevIndex, nextIndex);
  }

  return indices;
}


