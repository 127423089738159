import React, { useEffect } from "react";
import { IconButton, Typography, Tooltip, Checkbox } from "@mui/material";
import { ArrowBack, Clear } from "@mui/icons-material";
import { useState } from "react";
import { PopoverListButtons } from "../../../Utils/styledComps";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import { Style } from "paper/dist/paper-core";
// import { container } from "webpack";
const CustomViewPopoverContent = ({
  selectedFeature,
  view,
  mainCanvas,
  handleBackButtonClick,
  handlePopoverClose,
  horizonalGuidesRef,
  verticalGuidesRef,
  showGuides,
  showRuler,
  onAddCanvas,
  activeCanvasIndex,
  switchCanvas,
  canvasArray,
  setShowGuides,
  containerDim,
  setContainerDim,
}) => {
  const [gridVisible, setGridVisible] = useState(true);
  const [selectedOption, setSelectedOption] = useState(activeCanvasIndex);
  useEffect(() => {
    if (mainCanvas) {
      console.log("mainCanvasheight", mainCanvas.height);
      console.log("mainCanvaswidth", mainCanvas.width);
      console.log("on_Add_Canvas", onAddCanvas);
      console.log("canvasArray", canvasArray);
      console.log("activeCanvasIndex", activeCanvasIndex);
      console.log("switchCanvas", switchCanvas);
    }
    console.log("mainCanvas is show: ", mainCanvas);
    console.log("horizonalGuidesRef is show: ", horizonalGuidesRef);
    console.log("verticalGuidesRef is show: ", verticalGuidesRef);
  }, [mainCanvas, horizonalGuidesRef, verticalGuidesRef]);

  const renderPopoverContent = () => {
    if (selectedFeature) {
      const feature = view.find((item) => item.label === selectedFeature);
      // Function to handle actions on the mainCanvas for grid

      return (
        <div style={{ height: "500px", width: "300px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginRight: "10px",
              marginLeft: "10px",
            }}
          >
            <IconButton
              edge="start"
              sx={{ borderRadius: 0 }}
              onClick={handleBackButtonClick}
            >
              <ArrowBack />
            </IconButton>
            <Typography
              sx={{ m: 0, fontSize: 18, color: "#f0f2f7", fontWeight: 800 }}
            >
              {selectedFeature}
            </Typography>
            <IconButton
              edge="end"
              sx={{ borderRadius: 0 }}
              onClick={handlePopoverClose}
            >
              <Clear />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              columnGap: 8,
              margin: "10px",
              flexWrap: "wrap",
            }}
          >
            {feature?.children?.map((obj, index) => (
              <Tooltip key={index} title={obj.label}>
                <PopoverListButtons onClick={obj.onClick}>
                  {obj.icon}
                </PopoverListButtons>
              </Tooltip>
            ))}
          </div>
        </div>
      );
    }

    // for grid function
    const handleToggleGrid = () => {
      let visible = !gridVisible;
      setGridVisible(visible);
      console.log("grid visible");

      if (mainCanvas) {
        const activeGrids = mainCanvas._objects;
        console.log("activeGrids", activeGrids);

        activeGrids.forEach((obj) => {
          if (obj?.name === "gridLine") {
            obj.set({ visible: visible });
            console.log("iterate for grid", obj?.name);
          }
        });
        mainCanvas.renderAll();
      }
    };
    // for guides function
    const handleToggleGuides = () => {
      let visible = !showGuides;
      console.log("Guides visibility:", visible);
      setShowGuides(visible);
    };

    const handleFullPage = () => {
      console.log("containerDim height:", containerDim.height);
      console.log("containerDim width:", containerDim.width);

      const canvasHeight = mainCanvas.getHeight() / mainCanvas.getZoom();
      const canvasWidth = mainCanvas.getWidth() / mainCanvas.getZoom();
      console.log("canvas Width and height", canvasWidth, canvasHeight);

      if (containerDim && canvasHeight && canvasWidth) {
        const scaleFactorHeight = containerDim.height / canvasHeight;
        const scaleFactorWidth = containerDim.width / canvasWidth;

        const scaleFactor = Math.min(scaleFactorHeight, scaleFactorWidth);
        console.log(
          "scaleFactor",
          scaleFactor,
          canvasWidth,
          canvasHeight,
          containerDim
        );

        mainCanvas.setZoom(scaleFactor);

        mainCanvas.renderAll();

        console.log(
          "New canvas dimensions (zoom applied):",
          mainCanvas.getWidth() / mainCanvas.getZoom(),
          mainCanvas.getHeight() / mainCanvas.getZoom()
        );

        if (scaleFactor < 1) {
          console.log("Scaling down canvas to fit within the container.");

          mainCanvas.setZoom(scaleFactor);

          mainCanvas.setHeight(
            (canvasHeight - containerDim.paddingTop - containerDim.marginTop) *
              scaleFactor
          );
          mainCanvas.setWidth(canvasWidth * scaleFactor);

          console.log(
            "Canvas resized to:",
            canvasWidth * scaleFactor,
            canvasHeight * scaleFactor
          );
        } else if (scaleFactor > 1) {
          console.log("Canvas is smaller than container. No need to scale up.");
          mainCanvas.setZoom(1);
        } else {
          console.log("Canvas dimensions are equal to the container.");
          mainCanvas.setZoom(1);
        }

        mainCanvas.renderAll();
      }
    };

    // clickAddPages

    const handleSelectChange = (event) => {
      const selectedValue = event.target.value;
      setSelectedOption(selectedValue);
      switchCanvas(parseInt(selectedValue));
      console.log("Selected Option:", selectedValue);
    };

    const clickAddPages = () => {
      if (onAddCanvas) {
        onAddCanvas();
        console.log("page is added");
      }
    };

    return (
      <div style={{ height: "500px", width: "300px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: "10px",
          }}
        >
          <Typography
            sx={{
              m: 0,
              pl: 2,
              fontSize: 18,
              color: "#f0f2f7",
              fontWeight: 800,
            }}
          >
            View
          </Typography>
          <IconButton
            edge="end"
            sx={{ borderRadius: 0 }}
            onClick={handlePopoverClose}
          >
            <Clear />
          </IconButton>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flexstart",
              flexDirection: "column",
              alignItems: "space-between",
              gap: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "50px",
                alignItems: "center",
              }}
            >
              <label style={{ color: "#fff", fontWeight: "bold" }}>Pages</label>
              <select
                onChange={handleSelectChange}
                value={selectedOption}
                style={{
                  fontSize: "15px",
                  cursor: "pointer",
                  padding: "4px",
                  border: "none",
                  borderRadius: "2px 5px",
                  outline: "none",
                }}
              >
                {canvasArray.map((canvas, index) => (
                  <option key={index} value={index}>
                    {`Page ${index + 1}`}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "60px",
                alignItems: "center",
              }}
            >
              <label style={{ color: "#fff", fontWeight: "bold" }}>Total</label>
              <span style={{ color: "#fff" }}>{canvasArray.length} pages</span>
            </div>
          </div>

          {/* button */}
          <div>
            <button
              onClick={clickAddPages}
              style={{
                fontSize: "19px",
                borderRadius: "4px",
                cursor: "pointer",
                border: "none",
                padding: "21px 14px",
              }}
            >
              +
            </button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            columnGap: 8,
            margin: "10px",
            flexWrap: "wrap",
          }}
        >
          {view.map((obj, index) => {
            if (
              obj.label === "Grid" ||
              obj.label === "Guides" ||
              obj.label === "Ruler"
            ) {
              const checked =
                obj.label === "Grid"
                  ? gridVisible
                  : obj.label === "Guides"
                  ? showGuides
                  : showRuler;

              const handleToggle =
                obj.label === "Grid"
                  ? handleToggleGrid
                  : obj.label === "Guides"
                  ? handleToggleGuides
                  : obj.label === "Ruler" && obj.onClick;

              return (
                <Tooltip key={index} title={obj.label}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      checked={checked}
                      onChange={handleToggle}
                      icon={obj.icon}
                      checkedIcon={obj.icon}
                    />
                  </div>
                </Tooltip>
              );
            }

            // For other items, just show the button without a checkbox
            return (
              <Tooltip key={index} title={obj.label}>
                <PopoverListButtons
                  onClick={() => {
                    if (obj.label === "Zoom To Page") {
                      handleFullPage();
                    }
                    obj.onClick();
                  }}
                >
                  {obj.icon}
                </PopoverListButtons>
              </Tooltip>
            );
          })}
        </div>
      </div>
    );
  };

  return <>{renderPopoverContent()}</>;
};

export default CustomViewPopoverContent;
