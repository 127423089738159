// buttonActions.js
import { fabric } from 'fabric';
import { ArrowConnectorLine, CirclePath, CurvedArrowConnector, CurvedDoubleArrowConnector, CurvedPathConnector, DoubleArrowConnectorLine, ElbowConnectorLine, ElbowConnectorLineWithArrow, ElbowConnectorLineWithDoubleArrow, SnippedRectangle, StraightConnectorLine } from '../../Utils/customFabClasses';
import { createEllipseControls, createPointControls, removeCustomControls } from '../../Utils/polygonControls';
// Handle download SVG
export const handleGroupObject = (canvas, group) => {
  if (group.type === 'group') {
    const objectsInGroup = group._objects.slice();
    const groupLeft = group.left;
    const groupTop = group.top;
    const groupScaleX = group.scaleX;
    const groupScaleY = group.scaleY;

    canvas.discardActiveObject();
    canvas.remove(group);

    objectsInGroup.forEach(obj => {
      obj.selectable = true;
      obj.hasControls = true;
      obj.hasBorders = true;
      obj.left = obj.left * groupScaleX + groupLeft;
      obj.top = obj.top * groupScaleY + groupTop;
      obj.scaleX *= groupScaleX;
      obj.scaleY *= groupScaleY;

      obj.group = null;

      canvas.add(obj);
    });

    canvas.renderAll();
  }
};
export const handleSelectionCreated = (e,setPath) => {
  const selectedObject = e.selected;
  if (selectedObject) {
    console.log("fabricToPaperPath: handleSelectionCreated:", selectedObject[0].top, selectedObject[0].left, selectedObject[0].width, selectedObject[0].height, "selectedObject[0] & selectedObject", selectedObject[0], selectedObject)

    setPath(selectedObject[0]); 
  }

};

export const togglePathSelect = (canvas,pathSelect,setActiveNodes, activeNodes) =>{
  if(canvas){
    let activeObject = canvas.getActiveObject();
      if(activeObject){
        if(pathSelect){
          handleMouseDoubleClick(canvas,setActiveNodes,activeNodes);
        }else{
          removeCustomControls(canvas,activeObject)
        }
      }
  }
}

export function addControlPointsToSelectedObjects(selectedObjects,canvas,isVisibleCenter) {
  selectedObjects.forEach((obj) => {
    if (obj.class === 'textBox') {
      return;
    }
    const center = obj.getCenterPoint();
    if (obj.group) {
      const group = obj.group.getCenterPoint();
      // Create or update the control point and text
      center.x += group.x;
      center.y += group.y;
    }
    const controlPoint = new fabric.Circle({
      left: center.x - 5,
      top: center.y - 5,
      radius: 5,
      fill: 'lightblue',
      stroke: 'darkblue',
      selectable: false,
      evented: false,
      visible: isVisibleCenter,
    });

    const text = new fabric.Text(`${Math.round(center.x)}, ${Math.round(center.y)}`, {
      left: center.x - 5,
      top: center.y - 25,
      fontSize: 12,
      fill: 'black',
      selectable: false,
      evented: false,
      visible: isVisibleCenter,
    });

    controlPoint.associatedObject = obj;
    text.associatedObject = obj;

    obj.controlPoint = controlPoint;
    obj.controlText = text;
    console.log('addControlPointsToSelectedObjects: clicked', canvas, selectedObjects, isVisibleCenter, )
    canvas.add(controlPoint);
    canvas.add(text);
  });
}

export function fabricPathToSVG(fabricPath) {
  // Get the full SVG string from the Fabric.js path object
  const svgString = fabricPath.toSVG();

  // Create a temporary SVG element to parse the SVG string
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');

  // Extract the 'd' attribute from the first path element
  const pathElement = svgDoc.querySelector('path');
  return pathElement ? pathElement.getAttribute('d') : '';
}

export const handleMouseDoubleClick = (newCanvas,setActiveNodes,activeNodes) => {
  const activeObject = newCanvas.getActiveObject();
  if (activeObject?.type === 'path') {
    newCanvas.isDrawingMode = false;
    newCanvas.selection = true;
    createPointControls(newCanvas, activeObject,setActiveNodes,activeNodes); // Assuming createPointControls is a function
  } else if (activeObject?.type === 'group') {
    handleGroupObject(newCanvas, activeObject); // Assuming handleGroupObject is a function
  } else if (activeObject?.type === 'ellipse') {
    newCanvas.isDrawingMode = false;
    newCanvas.selection = true;
    createEllipseControls(newCanvas, activeObject); // Assuming createEllipseControls is a function
  }
};
export const handleDownloadSVG = (canvas) => {
  if (canvas) {
    canvas.clone((fabricCanvas) => {
      fabricCanvas.setWidth(canvas.getWidth());
      fabricCanvas.setHeight(canvas.getHeight());
      fabricCanvas.absolutePan(new fabric.Point(0, 0));  // Ensure no pan offset
      fabricCanvas.viewportTransform = canvas.viewportTransform;  // Inherit the original canvas's viewport
    
      console.log('handleDownloadSVG: canvas:', fabricCanvas);

      const rect = new fabric.Rect({
        left: 0,
        top: 0,
        opacity: 0.1,
        width: fabricCanvas.getWidth()*2,  
        height: fabricCanvas.getHeight()*2,
        fill: 'rgba(211, 211, 211, 0.7)',
        stroke: 'grey', 
        selectable: false,
        evented: false,
      });

      const watermarkText = new fabric.Text('BioGraphix Watermark', {
        left: canvas.getWidth()/2, 
        top: canvas.getHeight()/2 , 
        fontSize: 30,
        fill: 'grey', // Text color
        originX: 'center',
        originY: 'center',
        selectable: false,
        evented: false,
        shadow: new fabric.Shadow({
          color: 'rgba(0,0,0,0.5)',
          offsetX: 2,
          offsetY: 2,
          blur: 3,
        }),
      });

      // Add both the rectangle and text to the cloned canvas
      fabricCanvas.add(rect);
      fabricCanvas.add(watermarkText);

      // Ensure the watermark text is on top of the rectangle
      fabricCanvas.bringToFront(watermarkText);

      // Convert the cloned canvas to SVG
      const svgData = fabricCanvas.toSVG();
      const blob = new Blob([svgData], { type: 'image/svg+xml' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'canvas_with_watermark.svg';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    });
  }
};

export const setBackgroundImage = (url,canvas) => {
  if (canvas) {
    fabric.Image.fromURL(url, (img) => {
      canvas.setBackgroundImage(img, () => {
        canvas.renderAll();
      }, {
        scaleX: canvas.width / img.width,
        scaleY: canvas.height / img.height
      });
    });
  }

};

export const addControlPoints = (circle,canvas) => {
  const radius = circle.radius;

  // Calculate the center of the circle
  const centerX = circle.left + radius;
  const centerY = circle.top + radius;

  // Create start and end control points
  const startControl = new fabric.Circle({
    left: centerX + radius * Math.cos(0),
    top: centerY + radius * Math.sin(0),
    radius: 5,
    fill: 'transparent',
    stroke: 'red',
    originX: 'center',
    originY: 'center',
    hasBorders: false,
    hasControls: false,
    selectable: true,
  });

  const endControl = new fabric.Circle({
    left: centerX + radius * Math.cos(0),
    top: centerY + radius * Math.sin(0),
    radius: 5,
    fill: 'transparent',
    stroke: 'blue',
    originX: 'center',
    originY: 'center',
    hasBorders: false,
    hasControls: false,
    selectable: true,
  });

  canvas.add(startControl);
  canvas.add(endControl);

  // Assign control points to circle for later reference
  circle.startControl = startControl;
  circle.endControl = endControl;

  // Update control positions
  const updateControlPositions = () => {
    const startRad = (Math.PI / 180) * circle.startAngle;
    const endRad = (Math.PI / 180) * circle.endAngle;
    const scaledRadiusX = circle.radius * circle.scaleX;
    const scaledRadiusY = circle.radius * circle.scaleY;

    startControl.set({
      left: circle.left + circle.radius + scaledRadiusX * Math.cos(startRad),
      top: circle.top + circle.radius + scaledRadiusY * Math.sin(startRad),
    });

    endControl.set({
      left: circle.left + circle.radius + scaledRadiusX * Math.cos(endRad),
      top: circle.top + circle.radius + scaledRadiusY * Math.sin(endRad),
    });

    startControl.setCoords();
    endControl.setCoords();
    canvas.renderAll();
  };

  // Update circle angles and connecting line
  const updateCircleAnglesAndLine = (selectedStrokeColor) => {
    const centerX = circle.left + circle.radius;
    const centerY = circle.top + circle.radius;

    const startAngle = (Math.atan2(startControl.top - centerY, startControl.left - centerX) * 180) / Math.PI;
    const endAngle = (Math.atan2(endControl.top - centerY, endControl.left - centerX) * 180) / Math.PI;

    circle.set({
      startAngle: startAngle < 0 ? startAngle + 360 : startAngle,
      endAngle: endAngle < 0 ? endAngle + 360 : endAngle,
    });

    let linePath;
    if (circle.angleType === 'chord') {
      // Draw connecting line between control points
      linePath = new fabric.Path(`M ${startControl.left} ${startControl.top} L ${endControl.left} ${endControl.top}`, {
        stroke: selectedStrokeColor,
        fill: '',
        selectable: false,
      });
    } else if (circle.angleType === 'arc') {
      // Draw connecting lines to the center
      const pathString = `M ${startControl.left} ${startControl.top} L ${centerX} ${centerY} M ${centerX} ${centerY} L ${endControl.left} ${endControl.top}`;
      linePath = new fabric.Path(pathString, {
        stroke: selectedStrokeColor,
        fill: '',
        selectable: false,
      });
    }

    if (circle.line) {
      canvas.remove(circle.line);
    }
    circle.line = linePath;
    canvas.add(linePath);
    canvas.renderAll();
  };

  // Attach events
  const constrainMovement = (control) => {
    control.on('moving', (e) => {
      const angle = Math.atan2(control.top - (circle.top + circle.radius), control.left - (circle.left + circle.radius));
      const constrainedLeft = circle.left + circle.radius + circle.radius * Math.cos(angle);
      const constrainedTop = circle.top + circle.radius + circle.radius * Math.sin(angle);

      control.set({
        left: constrainedLeft,
        top: constrainedTop,
      });

      updateCircleAnglesAndLine();
      control.setCoords();
    });
  };

  constrainMovement(startControl);
  constrainMovement(endControl);

  circle.on('moving', () => {
    updateControlPositions();
  });

  circle.on('scaling', () => {
    updateControlPositions();
  });

  canvas.on('object:modified', updateControlPositions);

  // Initial positioning of control points
  updateControlPositions();
};
// Handle eraser click
export const handleEraserClick = (canvas, isErasing, setIsErasing) => {
  setIsErasing(!isErasing);
  if (canvas) {
    canvas.isDrawingMode = !isErasing;
  }
};
export function roundPathCorners(pathString, radius, useFractionalRadius) {
  function moveTowardsLength(movingPoint, targetPoint, amount) {
    var width = (targetPoint.x - movingPoint.x);
    var height = (targetPoint.y - movingPoint.y);
    
    var distance = Math.sqrt(width*width + height*height);
    
    return moveTowardsFractional(movingPoint, targetPoint, Math.min(1, amount / distance));
  }
  function moveTowardsFractional(movingPoint, targetPoint, fraction) {
    return {
      x: movingPoint.x + (targetPoint.x - movingPoint.x)*fraction,
      y: movingPoint.y + (targetPoint.y - movingPoint.y)*fraction
    };
  }
  
  // Adjusts the ending position of a command
  function adjustCommand(cmd, newPoint) {
    if (cmd.length > 2) {
      cmd[cmd.length - 2] = newPoint.x;
      cmd[cmd.length - 1] = newPoint.y;
    }
  }
  
  // Gives an {x, y} object for a command's ending position
  function pointForCommand(cmd) {
    return {
      x: parseFloat(cmd[cmd.length - 2]),
      y: parseFloat(cmd[cmd.length - 1]),
    };
  }
  
  // Split apart the path, handing concatonated letters and numbers
  var pathParts = pathString
    .split(/[,\s]/)
    .reduce(function(parts, part){
      var match = part.match("([a-zA-Z])(.+)");
      if (match) {
        parts.push(match[1]);
        parts.push(match[2]);
      } else {
        parts.push(part);
      }
      
      return parts;
    }, []);
  
  // Group the commands with their arguments for easier handling
  var commands = pathParts.reduce(function(commands, part) {
    if (parseFloat(part) == part && commands.length) {
      commands[commands.length - 1].push(part);
    } else {
      commands.push([part]);
    }
    
    return commands;
  }, []);
  
  // The resulting commands, also grouped
  var resultCommands = [];
  
  if (commands.length > 1) {
    var startPoint = pointForCommand(commands[0]);
    
    // Handle the close path case with a "virtual" closing line
    var virtualCloseLine = null;
    if (commands[commands.length - 1][0] == "Z" && commands[0].length > 2) {
      virtualCloseLine = ["L", startPoint.x, startPoint.y];
      commands[commands.length - 1] = virtualCloseLine;
    }
    
    // We always use the first command (but it may be mutated)
    resultCommands.push(commands[0]);
    
    for (var cmdIndex=1; cmdIndex < commands.length; cmdIndex++) {
      var prevCmd = resultCommands[resultCommands.length - 1];
      
      var curCmd = commands[cmdIndex];
      
      // Handle closing case
      var nextCmd = (curCmd == virtualCloseLine)
        ? commands[1]
        : commands[cmdIndex + 1];
      
      // Nasty logic to decide if this path is a candidite.
      if (nextCmd && prevCmd && (prevCmd.length > 2) && curCmd[0] == "L" && nextCmd.length > 2 && nextCmd[0] == "L") {
        // Calc the points we're dealing with
        var prevPoint = pointForCommand(prevCmd);
        var curPoint = pointForCommand(curCmd);
        var nextPoint = pointForCommand(nextCmd);
        
        // The start and end of the cuve are just our point moved towards the previous and next points, respectivly
        var curveStart, curveEnd;
        
        if (useFractionalRadius) {
          curveStart = moveTowardsFractional(curPoint, prevCmd.origPoint || prevPoint, radius);
          curveEnd = moveTowardsFractional(curPoint, nextCmd.origPoint || nextPoint, radius);
        } else {
          curveStart = moveTowardsLength(curPoint, prevPoint, radius);
          curveEnd = moveTowardsLength(curPoint, nextPoint, radius);
        }
        
        // Adjust the current command and add it
        adjustCommand(curCmd, curveStart);
        curCmd.origPoint = curPoint;
        resultCommands.push(curCmd);
        
        // The curve control points are halfway between the start/end of the curve and
        // the original point
        var startControl = moveTowardsFractional(curveStart, curPoint, .5);
        var endControl = moveTowardsFractional(curPoint, curveEnd, .5);
  
        // Create the curve 
        var curveCmd = ["C", startControl.x, startControl.y, endControl.x, endControl.y, curveEnd.x, curveEnd.y];
        // Save the original point for fractional calculations
        curveCmd.origPoint = curPoint;
        resultCommands.push(curveCmd);
      } else {
        // Pass through commands that don't qualify
        resultCommands.push(curCmd);
      }
    }
    
    // Fix up the starting point and restore the close path if the path was orignally closed
    if (virtualCloseLine) {
      var newStartPoint = pointForCommand(resultCommands[resultCommands.length-1]);
      resultCommands.push(["Z"]);
      adjustCommand(resultCommands[0], newStartPoint);
    }
  } else {
    resultCommands = commands;
  }
  
  return resultCommands.reduce(function(str, c){ return str + c.join(" ") + " "; }, "");
}




export const handleDrawingMode = (canvas, selectedColor,selectedStrokeColor, shape) => {
  if (!canvas) return;
  const createRoundedShape = (left, top, width, height, cornerRadius,pathData) => {
    const pathString = pathData;
  
    const roundedPath = roundPathCorners(pathString, cornerRadius, false);
    console.log("roundPathCorners: ", roundedPath);
    return new fabric.Path(roundedPath, {
      left,
      top,
      initialPathData:pathString,
      cornerRadius:cornerRadius,
      fill: selectedColor,
      stroke: selectedStrokeColor,
      strokeWidth: 2,
      selectable: true,
      evented: true
    });
  };
  let newShape = null;

  switch (shape) {
    case 'rectangle':
      newShape = new fabric.Path('M0 0 L 100 0 L 100 50 L 0 50 Z',{
        left: 100,
        top: 100,
        fill: selectedColor,
        stroke: selectedStrokeColor,
        strokeWidth:2,
        width: 100,
        height: 50,
        selectable: true
      });
      break;
    case 'roundedRectangle':
        var pathData = 'M0 0 L 100 0 L100 50 L 0 50 Z';
        newShape = createRoundedShape(
          50, 50, 200, 100, 5,pathData
        );
        break;
    
        case 'roundedSquare':
          let roundedRectPath = 'M 0 0 L 50 0 L 50 50 L 0 50 Z'; // 50x50 rectangle

          newShape = createRoundedShape(
            50, 50, 200, 100, 5,roundedRectPath
          );

          break;
          
        case 'oval':
          newShape = new fabric.Path('M 50,0 A 50,25 0 1,0 -50,0 A 50,25 0 1,0 50,0 Z', {
            left: 100,
            top: 100,
            fill: selectedColor,
            stroke: selectedStrokeColor,
            strokeWidth: 2,
            selectable: true
          });
          
      break;
      case 'roundedRectCallout':
        var boxPadding = 16;
        var arrowWidth = 16;
        var strokeWidth = 2;
        var handleSize = 24;
        var msg = '';

        var textbox = new fabric.Textbox(msg, {
          left: 200,
          top: 80,
          width: 180,
          fontSize: 16,
          originY: 'center',
          originX: 'center'
        });
        var setCoords = textbox.setCoords.bind(textbox);
          textbox.on({
            moving: setCoords,
            scaling: setCoords,
            rotating: setCoords
          });
          textbox.lastLeft = textbox.left;
          textbox.lastTop = textbox.top;
          
          var handle = new fabric.Rect({
            fill: 'transparent',
            left: 128,
            top: 160,
            width: handleSize,
            height: handleSize,
            hasRotatingPoint: false,
            hasControls: false,
            originY: 'center',
            originX: 'center'
          });
          
          var rect = new fabric.Rect({
            fill: 'white',
            stroke: selectedStrokeColor,
            strokeWidth: strokeWidth,
            rx: 8,
            ry: 8,
            objectCaching: false
          });
          var poly = new fabric.Polygon(
            [{x:0,y:0},{x:1,y:1},{x:1,y:0}],
            {
              fill: 'white',
              stroke: selectedStrokeColor,
              strokeWidth: strokeWidth,
              objectCaching: false
            }
          );
          var poly2 = new fabric.Polygon(
            [{x:0,y:0},{x:1,y:1},{x:1,y:0}],
            {
              fill: 'white',
              objectCaching: false
            }
          );

          canvas.add(poly, rect, poly2, textbox);
          canvas.add(handle).setActiveObject(handle);
          canvas.on('after:render', updateBubble);
          updateBubble();
          function updateBubble() {
            var x = textbox.left;
            var y = textbox.top;
            
            var bound = textbox.getBoundingRect();
            rect.left = bound.left - boxPadding;
            rect.top = bound.top - boxPadding;
            rect.width = bound.width + (boxPadding*2);
            rect.height = bound.height + (boxPadding*2);
            
            if(x !== textbox.lastLeft || 
               y !== textbox.lastTop) {
              handle.left += (x - textbox.lastLeft);
              handle.top += (y - textbox.lastTop);
              handle.setCoords();
            }
            
            var halfPi = Math.PI/2;
            var angleRadians = Math.atan2(handle.top - y, handle.left - x);
            var offsetX = Math.cos(angleRadians + halfPi);
            var offsetY = Math.sin(angleRadians + halfPi);
            
            poly.points[0].x = handle.left;
            poly.points[0].y = handle.top;
            poly.points[1].x = x - (offsetX * arrowWidth);
            poly.points[1].y = y - (offsetY * arrowWidth); 
            poly.points[2].x = x + (offsetX * arrowWidth);
            poly.points[2].y = y + (offsetY * arrowWidth);
            
            var halfStroke = strokeWidth/2;
            poly2.points[0].x = handle.left;
            poly2.points[0].y = handle.top;
            poly2.points[1].x = x - offsetX * (arrowWidth - halfStroke);
            poly2.points[1].y = y - offsetY * (arrowWidth - halfStroke);
            poly2.points[2].x = x + offsetX * (arrowWidth - halfStroke);
            poly2.points[2].y = y + offsetY * (arrowWidth - halfStroke);
            
            textbox.lastLeft = x;
            textbox.lastTop = y;
          }
          return;      
    case 'square':
      const rectPath = 'M 0 0 L 50 0 L 50 50 L 0 50 Z'; 

      // Create the Path object
      newShape = new fabric.Path(rectPath, {
          left: 100,
          top: 100,
          fill: selectedColor,
          stroke: selectedStrokeColor,
          strokeWidth: 2,
          selectable: true
      });
      
      break;
      case 'circle':
        newShape = new CirclePath(canvas, {
          left: 100,
          top: 100,
          radius: 50,
          startAngle: 0,
          angleType:'arc',
          endAngle: 360,
          fill: selectedColor,
          selectable: true,
          evented: true
      });
        return;
      case 'circle1':
  const addCircleWithAngles = () => {
    const circle = new fabric.Circle({
      left: 200,
      top: 200,
      fill: selectedColor,
      stroke: selectedStrokeColor,
      strokeWidth:2,
      radius: 50,
      class: 'circle',
      startAngle: 0,
      endAngle: 360,
      selectable: true,
    });

    canvas.add(circle);

    // Check if angleType is defined, then add control points
    if (circle.angleType) {
      addControlPoints(circle);
    }

    // Monitor circle's property changes to add/remove control points as needed
    circle.on('modified', () => {
      if (circle.angleType) {
        if (!circle.startControl || !circle.endControl) {
          addControlPoints(circle);
        }
      } else {
        if (circle.startControl && circle.endControl) {
          canvas.remove(circle.startControl);
          canvas.remove(circle.endControl);
          canvas.remove(circle.line);
          circle.startControl = null;
          circle.endControl = null;
          circle.line = null;
        }
      }
    });
  };

  const addControlPoints = (circle) => {
    const radius = circle.radius;

    // Calculate the center of the circle
    const centerX = circle.left + radius;
    const centerY = circle.top + radius;

    // Create start and end control points
    const startControl = new fabric.Circle({
      left: centerX + radius * Math.cos(0),
      top: centerY + radius * Math.sin(0),
      radius: 5,
      fill: 'transparent',
      stroke: 'red',
      originX: 'center',
      originY: 'center',
      hasBorders: false,
      hasControls: false,
      selectable: true,
    });

    const endControl = new fabric.Circle({
      left: centerX + radius * Math.cos(0),
      top: centerY + radius * Math.sin(0),
      radius: 5,
      fill: 'transparent',
      stroke: 'blue',
      originX: 'center',
      originY: 'center',
      hasBorders: false,
      hasControls: false,
      selectable: true,
    });

    canvas.add(startControl);
    canvas.add(endControl);

    // Assign control points to circle for later reference
    circle.startControl = startControl;
    circle.endControl = endControl;

    // Update control positions
    const updateControlPositions = () => {
      const startRad = (Math.PI / 180) * circle.startAngle;
      const endRad = (Math.PI / 180) * circle.endAngle;
      const scaledRadiusX = circle.radius * circle.scaleX;
      const scaledRadiusY = circle.radius * circle.scaleY;

      startControl.set({
        left: circle.left + circle.radius + scaledRadiusX * Math.cos(startRad),
        top: circle.top + circle.radius + scaledRadiusY * Math.sin(startRad),
      });

      endControl.set({
        left: circle.left + circle.radius + scaledRadiusX * Math.cos(endRad),
        top: circle.top + circle.radius + scaledRadiusY * Math.sin(endRad),
      });

      startControl.setCoords();
      endControl.setCoords();
      canvas.renderAll();
    };

    // Update circle angles and connecting line
    const updateCircleAnglesAndLine = () => {
      const centerX = circle.left + circle.radius;
      const centerY = circle.top + circle.radius;

      const startAngle = (Math.atan2(startControl.top - centerY, startControl.left - centerX) * 180) / Math.PI;
      const endAngle = (Math.atan2(endControl.top - centerY, endControl.left - centerX) * 180) / Math.PI;

      circle.set({
        startAngle: startAngle < 0 ? startAngle + 360 : startAngle,
        endAngle: endAngle < 0 ? endAngle + 360 : endAngle,
      });

      let linePath;
      if (circle.angleType === 'chord') {
        // Draw connecting line between control points
        linePath = new fabric.Path(`M ${startControl.left} ${startControl.top} L ${endControl.left} ${endControl.top}`, {
          stroke: selectedStrokeColor,
          fill: '',
          selectable: false,
        });
      } else if (circle.angleType === 'arc') {
        // Draw connecting lines to the center
        const pathString = `M ${startControl.left} ${startControl.top} L ${centerX} ${centerY} M ${centerX} ${centerY} L ${endControl.left} ${endControl.top}`;
        linePath = new fabric.Path(pathString, {
          stroke: selectedStrokeColor,
          fill: '',
          selectable: false,
        });
      }

      if (circle.line) {
        canvas.remove(circle.line);
      }
      circle.line = linePath;
      canvas.add(linePath);
      canvas.renderAll();
    };

    // Attach events
    const constrainMovement = (control) => {
      control.on('moving', (e) => {
        const angle = Math.atan2(control.top - (circle.top + circle.radius), control.left - (circle.left + circle.radius));
        const constrainedLeft = circle.left + circle.radius + circle.radius * Math.cos(angle);
        const constrainedTop = circle.top + circle.radius + circle.radius * Math.sin(angle);

        control.set({
          left: constrainedLeft,
          top: constrainedTop,
        });

        updateCircleAnglesAndLine(selectedStrokeColor);
        control.setCoords();
      });
    };

    constrainMovement(startControl);
    constrainMovement(endControl);

    circle.on('moving', () => {
      updateControlPositions();
    });

    circle.on('scaling', () => {
      updateControlPositions();
    });

    canvas.on('object:modified', updateControlPositions);

    // Initial positioning of control points
    updateControlPositions();
  };

  // Add circle with control points to canvas
  addCircleWithAngles();

  return;

      case 'triangle':
      newShape = new fabric.Path('M 50 0 L 100 100 L 0 100 Z', {
        left: 100,
        top: 100,
        fill: selectedColor,
        stroke: selectedStrokeColor,
        strokeWidth: 2,
        selectable: true
      });
      
      break;
      case 'star':
   
    var pathData = `M 349.9 75 L 379 160.9 L 469 160.9 L 397 214.9 L 423 300.9 L 350 249.9 L 276.9 301 L 303 215 L 231 161 L 321 161 L 349.9 75`;
    
    console.log("Star Path:", pathData);

    newShape = new fabric.Path(pathData, {
        left: 100,
        top: 100,
        class: 'star',
        fill: selectedColor,
        stroke: selectedStrokeColor,
        spokeRatio:0.4,
        strokeWidth: 2,
        selectable: true,
        corners:5,
        cornerRadius:0,
        scaleX: 0.5,
        scaleY: 0.5
    });

    break;

        case 'cylinder':
          const cylinderPathData = `
  M 328,173
  A 74,22 0 1,1 476,173
  A 74,22 0 1,1 328,173
  M 328,375
  A 74,22 0 1,1 476,375
  A 74,22 0 1,1 328,375
  M 328,173
  L 328,375
  M 476,173
  L 476,375
`;

newShape = new fabric.Path(cylinderPathData, {
  left: 100,
  top: 100,
  fill: selectedColor, // Fill color for the cylinder
  stroke: selectedStrokeColor,
  strokeWidth: 2, // Width of lines
  selectable: true
});

break;

case 'cube':
  newShape = new fabric.Path('m 0 0 L 100 0 L 100 100 L 0 100 Z L 50 -20 l 100 0 l -50 20 m 50 -20 l 0 100 l -50 20 l 0 -100 Z', {
    left: 100,
    top: 100,
    fill: selectedColor, // Transparent fill to visualize edges better
    stroke: selectedStrokeColor,
    strokeWidth: 2,
    selectable: true
  });
  

  break;

      case 'trapezium':
        newShape = new fabric.Path(
          'M 0 0 L 60 0 L 100 60 L -40 60 Z',
          {
            left: 100,
            top: 100,
            fill: selectedColor,
            stroke: selectedStrokeColor,
            strokeWidth:2,
            selectable: true
          }
        );
        break;
      
        case 'roundedTrapezium':
          let polygonData = [{ x: 0, y: 0 },
            { x: 60, y: 0 },
            { x: 100, y: 60 },
            { x: -40, y: 60 }];
            
            
          
          newShape = createRoundedShape(
            50, 50, 200, 100, 5,'M 0 0 L 60 0 L 100 60 L -40 60 Z'
          );
          
          break;
        
      case 'rhombus':
        newShape = new fabric.Path('M 10 0 L 60 0 L 50 50 L 0 50 Z',{
          left: 100,
            top: 100,
            fill: selectedColor,
            stroke: selectedStrokeColor,
            strokeWidth:2,
            selectable: true
        })
      break;
      case 'roundedRhombus':
        newShape = createRoundedShape(
          50, 50, 200, 100, 5,'M 10 0 L 60 0 L 50 50 L 0 50 Z'
        );
      break;
      case 'polygon':
        let pathString = 'M 50 0 L 100 50 L 75 100 L 25 100 L 0 50 L 50 0';

        newShape = new fabric.Path(
          pathString,
          {
            left: 100,
            top: 100,
            fill: selectedColor,
            stroke: selectedStrokeColor,
            corners:5,
            class:'polygon',
            spokeRatio:1,
            strokeWidth:2,
            selectable: true
          }
        );
  
        break;
      
      case 'line':
        newShape = new StraightConnectorLine(canvas, {
          startX: 50,
          startY: 50,
          endX: 200,
          endY: 200
      });
        return;
      case 'arrow':        
      
        newShape = new ArrowConnectorLine(canvas, {
          startX: 50,
          startY: 50,
          endX: 200,
          endY: 200
      });
      

        return;
      
      //   case 'curvedArrow':        
      
      //   newShape = new ArrowConnectorLine(canvas, {
      //     startX: 50,
      //     startY: 50,
      //     endX: 200,
      //     endY: 200
      // });
      

      //   return;
      case 'doubleArrow':
        newShape = new DoubleArrowConnectorLine(canvas, {
          startX: 50,
          startY: 50,
          elbowX: 150,
          elbowY: 150,
          endX: 250,
          endY: 150
      });
      return;
      //   case 'doubleArrow':
      //     var triangleOne = new fabric.Triangle({
      //       width: 10, 
      //       height: 15, 
      //       fill: 'black', 
      //       left: 65, 
      //       top: 76,
      //       angle: 270
      //   });
      //   var triangleTwo = new fabric.Triangle({
      //     width: 10, 
      //     height: 15, 
      //     fill: 'black', 
      //     left: 235, 
      //     top: 66,
      //     angle: 90
      // });
  
      //   var line = new fabric.Line([50, 100, 200, 100], {
      //       left: 75,
      //       top: 70,
      //       strokeWidth:2,
      //       stroke: selectedStrokeColor
      //   });
  
      //   var objs = [triangleOne, line, triangleTwo];
  
      //     newShape = new fabric.Group(objs);
  
      //     break;
          
          case 'doubleSidedArrow':
            var arrowLength = 100;
            var arrowWidth = 20;
            var arrowHeadLength = 30;
            var arrowHeadWidth = 40;
        
            // Define the path for the double-sided arrow
            var pathData = [
                // Left arrow head
                'M', 50, 100, // Tip of the left arrowhead
                'L', 50 + arrowHeadWidth, 100 - arrowWidth / 2 - arrowHeadLength / 2, // Top of the left arrowhead
                'L', 50 + arrowHeadWidth, 100 - arrowWidth / 2, // Start of the left arrow shaft
                
                // Left arrow shaft
                'L', 50 + arrowLength + arrowHeadWidth, 100 - arrowWidth / 2, // End of the left arrow shaft
                'L', 50 + arrowLength + arrowHeadWidth, 100 - arrowWidth / 2 - arrowHeadLength / 2, // Top of the right arrowhead
                'L', 50 + arrowLength + arrowHeadWidth + arrowHeadWidth, 100, // Tip of the right arrowhead
                
                // Right arrow head
                'L', 50 + arrowLength + arrowHeadWidth, 100 + arrowWidth / 2 + arrowHeadLength / 2, // Bottom of the right arrowhead
                'L', 50 + arrowLength + arrowHeadWidth, 100 + arrowWidth / 2, // Start of the right arrow shaft
                
                // Right arrow shaft
                'L', 50 + arrowHeadWidth, 100 + arrowWidth / 2, // End of the right arrow shaft
                'L', 50 + arrowHeadWidth, 100 + arrowWidth / 2 + arrowHeadLength / 2, // Bottom of the left arrowhead
                'Z' // Close the path
            ].join(' ');
        
            var doubleArrow = new fabric.Path(pathData, {
                fill: selectedColor,
                stroke: selectedStrokeColor,
                strokeWidth:2
            });
        
            newShape = doubleArrow;
        
            break;
            case 'dashedLine':
              newShape = new fabric.Line([0, 50, 150, 50], {
                // strokeDashArray: [5, 5],
                stroke: selectedStrokeColor
            });
            
            break;
            case 'curvedPolyArrow':
              newShape = new CurvedArrowConnector(canvas, {
                startX: 50,
                startY: 50,
                endX: 200,
                endY: 200
            });
            
              return;
              case 'curvedLine':
              newShape = new CurvedPathConnector(canvas, {
                startX: 50,
                startY: 50,
                endX: 200,
                endY: 200
            });
            
              return;
              case 'curvedPolyDoubleArrow':
              newShape = new CurvedDoubleArrowConnector(canvas, {
                startX: 50,
                startY: 50,
                endX: 200,
                endY: 200
            });
              return;
              
            case 'curvedArrow':
              newShape = new fabric.Path('m 60 0 l 0 15 m 0 -15 a 40 30 0 0 0 0 80 m 0 -65 a 40 30 0 0 0 0 80 l 0 10 m 0 -25 l 0 -10 l 20 17.5 l -20 17.5', {
                  fill: selectedColor,
                  stroke: selectedStrokeColor,
                  strokeWidth:2,
                  left: 200,
                  top: 50
              });
              break;
              case 'curvedArrowLeft':
              newShape = new fabric.Path('m 60 0 l 0 15 m 0 -15 a 40 30 0 0 0 0 80 m 0 -65 a 40 30 0 0 0 0 80 l 0 10 m 0 -25 l 0 -10 l 20 17.5 l -20 17.5', {
                  fill: selectedColor,
                  stroke: selectedStrokeColor,
                  strokeWidth:2,
                  left: 200,
                  top: 50,
                  flipX:true
              });
              break;
              case 'curvedArrowTop':
              newShape = new fabric.Path('m 60 0 l 0 15 m 0 -15 a 40 30 0 0 0 0 80 m 0 -65 a 40 30 0 0 0 0 80 l 0 10 m 0 -25 l 0 -10 l 20 17.5 l -20 17.5', {
                  fill: selectedColor,
                  stroke: selectedStrokeColor,
                  strokeWidth:2,
                  left: 200,
                  top: 100,
                  angle:270
              });
              break;
              case 'curvedArrowBottom':
              newShape = new fabric.Path('m 60 0 l 0 15 m 0 -15 a 40 30 0 0 0 0 80 m 0 -65 a 40 30 0 0 0 0 80 l 0 10 m 0 -25 l 0 -10 l 20 17.5 l -20 17.5', {
                  fill: selectedColor,
                  stroke: selectedStrokeColor,
                  strokeWidth:2,
                  left: 200,
                  top: 100,
                  angle:90,
                  flipY:true
              });
              break;
              case 'stripedArrow':
              newShape = new fabric.Path('m 0 0 l 0 60 l 10 0 l 0 -60 z m 17.5 0 l 0 60 l 15 0 l 0 -60 z m 22.5 0 l 0 60 l 40 0 l 0 20 l 30 -50 l -30 -50 l 0 20 z ', {
                  fill: selectedColor,
                  stroke: selectedStrokeColor,
                  strokeWidth:2,
                  left: 200,
                  top: 50,
              });
              break;
              case 'notchedArrow':
              newShape = new fabric.Path('m 0 20 l 60 0 l 0 -10 l 30 30 l -30 30 l 0 -10 l -60 0 l 25 -20 z', {
                  fill: selectedColor,
                  stroke: selectedStrokeColor,
                  strokeWidth:2,
                  left: 200,
                  top: 50
              });
              break;
              case 'chevronArrow':
              newShape = new fabric.Path('m 0 0 l 70 0 l 40 30 l -40 30 l -70 0 l 40 -30 z', {
                  fill: selectedColor,
                  stroke: selectedStrokeColor,
                  strokeWidth:2,
                  left: 200,
                  top: 50
              });
              break;
              case 'rightCalloutArrow':
                newShape = new fabric.Path('m 0 0 l 50 0 l 0 40 l 20 0 l 0 -10 l 10 20 l -10 20 l 0 -10 l -20 0 l 0 40 l -50 0 z', {
                    fill: selectedColor,
                    stroke: selectedStrokeColor,
                    strokeWidth:2,
                    left: 200,
                    top: 50
                });
                break;
                case 'leftRightCalloutArrow':
                  newShape = new fabric.Path('m 30 0 l 50 0 l 0 40 l 20 0 l 0 -10 l 10 20 l -10 20 l 0 -10 l -20 0 l 0 40 l -50 0 l 0 -40 l -20 0 l 0 10 l -10 -20 l 10 -20 l 0 10 l 20 0 z', {
                      fill: selectedColor,
                      stroke: selectedStrokeColor,
                      strokeWidth:2,
                      left: 200,
                      top: 50
                  });
                  break;
                  
                  case 'quadCalloutArrow':
                    newShape = new fabric.Path('m 30 50 l 20 0 l 0 -20 l -10 0 l 20 -10 l 20 10 l -10 0 l 0 20 l 20 0 l 0 20 l 20 0 l 0 -10 l 10 20 l -10 20 l 0 -10 l -20 0 l 0 20 l -20 0 l 0 20 l 10 0 l -20 10 l -20 -10 l 10 0 l 0 -20 l -20 0 l 0 -20 l -20 0 l 0 10 l -10 -20 l 10 -20 l 0 10 l 20 0 z ', {
                        fill: selectedColor,
                        stroke: selectedStrokeColor,
                        strokeWidth:2,
                        left: 200,
                        top: 50
                    });
                    break;
            
          // Case for Right-angle arrow
          case 'rightAngleArrow':
              newShape = new fabric.Path('m 20 60 l 0 -25 q 0 -20 25 -20 l 0 -12 l 9 15 l -9 15 l 0 -12 q -18 0 -18 15 l 0 24 z ', {
                  fill: '#1C5B73',
                  stroke: selectedStrokeColor,
                  strokeWidth:2,
                  left: 350,
                  top: 50
              });
              break;
          case 'bigRightArrow':
            var arrowLength = 100;
            var arrowWidth = 20;
            var arrowHeadLength = 30;
            var arrowHeadWidth = 40;
            
            // Define the path for the arrow
            var pathData = [
                'M', 50, 100 - arrowWidth / 2, // Start at left side of the arrow shaft
                'L', 50 + arrowLength, 100 - arrowWidth / 2, // Line to the end of the shaft
                'L', 50 + arrowLength, 100 - arrowWidth / 2 - arrowHeadLength / 2, // Line to the bottom of the arrowhead
                'L', 50 + arrowLength + arrowHeadWidth, 100, // Line to the tip of the arrowhead
                'L', 50 + arrowLength, 100 + arrowWidth / 2 + arrowHeadLength / 2, // Line to the top of the arrowhead
                'L', 50 + arrowLength, 100 + arrowWidth / 2, // Line back to the shaft
                'L', 50, 100 + arrowWidth / 2, // Line to the start of the shaft
                'Z' // Close the path
            ].join(' ');
        
            var arrow = new fabric.Path(pathData, {
                fill: selectedColor,
                stroke: selectedStrokeColor,
                strokeWidth:2,
            });
        
            newShape = arrow;
        
            break;
            case 'elbowConnectorLine':
              newShape = new ElbowConnectorLine(canvas, {
                startX: 50,
                startY: 50,
                elbowX: 150,
                elbowY: 150,
                endX: 250,
                endY: 150
            });
            newShape.setStrokeStyle("solid")
            return;
            
            case 'elbowConnectorLineArrow':
              newShape = new ElbowConnectorLineWithArrow(canvas, {
                startX: 50,
                startY: 50,
                elbowX: 150,
                elbowY: 150,
                endX: 250,
                endY: 150,
            });
            return;

            case 'elbowConnectorLineDoubleArrow':
              newShape = new ElbowConnectorLineWithDoubleArrow(canvas, {
                startX: 50,
                startY: 50,
                elbowX: 150,
                elbowY: 150,
                endX: 250,
                endY: 150,
            });
            return;
            
            case 'quadArrows':
              newShape = new fabric.Path('m 50 0 l 20 20 l -10 0 l 0 30 l 30 0 l 0 -10 l 20 20 l -20 20 l 0 -10 l -30 0 l 0 30 l 10 0 l -20 20 l -20 -20 l 10 0 l 0 -30 l -30 0 l 0 10 l -20 -20 l 20 -20 l 0 10 l 30 0 l 0 -30 l -10 0 z',{
                top:100,
                left:100,
                fill:selectedColor,
                stroke:'black',
                strokeWidth:1
              })
            break;
            case 'tripleArrows':
              newShape = new fabric.Path('m 50 0 l 20 20 l -10 0 l 0 30 l 30 0 l 0 -10 l 20 20 l -20 20 l 0 -10 l -80 0 l 0 10 l -20 -20 l 20 -20 l 0 10 l 30 0 l 0 -30 l -10 0 z',{
                top:100,
                left:100,
                fill:selectedColor,
                stroke:'black',
                strokeWidth:1
              })
            break;
            case '270Arrow':
              newShape = new fabric.Path('m 80 0 l 20 20 l -10 0 l 0 80 l -80 0 l 0 -20 l 60 0 l 0 -60 l -10 0 z',{
                top:100,
                left:100,
                fill:selectedColor,
                stroke:'black',
                strokeWidth:1
              })
            break;
            case '270DoubleArrow':
              newShape = new fabric.Path('m 80 0 l 20 20 l -10 0 l 0 80 l -80 0 l 0 10 l -20 -20 l 20 -20 l 0 10 l 60 0 l 0 -60 l -10 0 z ',{
                top:100,
                left:100,
                fill:selectedColor,
                stroke:'black',
                strokeWidth:1
              })
            break;
            case 'uTurnArrow':
              newShape = new fabric.Path('m 20 100 l 0 -40 a 1 1 0 0 1 70 0 l 0 10 l 10 0 l -20 20 l -20 -20 l 10 0 l 0 -10 a 1 1 0 0 0 -30 0 l 0 40 z ',{
                top:100,
                left:100,
                fill:selectedColor,
                stroke:'black',
                strokeWidth:1
              })
            break;
            case 'singleSnippedRect':
              var width = 100;
              var height = 50;
              var cornerSize = 10; 
              var pathData = [
                  'M', 0, 0,                    
                  'L', width - cornerSize, 0,   
                  'L', width, cornerSize,       
                  'L', width, height,           
                  'L', 0, height,               
                  'Z'                           
              ].join(' ');

              newShape = new fabric.Path(pathData, {
                  left: 50,  
                  top: 50,   
                  fill: selectedColor, 
                  stroke: selectedStrokeColor,
                  strokeWidth: 2  
              });

            
            break;
              
            case 'doubleSameSnippedRect':
              var width = 150;
            var height = 100;
            var cornerSize = 20; // Size of the snipped corners

            // Define path data for the rectangle with snipped corners
            var pathData = [
                'M', 0, cornerSize,           
                'L', cornerSize, 0,           
                'L', width - cornerSize, 0,   
                'L', width, cornerSize,       
                'L', width, height,           
                'L', 0, height,               
                'Z'                           
            ].join(' ');

            // Create Fabric.js path object
            newShape = new fabric.Path(pathData, {
                left: 50,        
                top: 50,         
                fill: selectedColor,
                stroke: selectedStrokeColor, 
                strokeWidth: 2  
            });            
            break;

            case 'doubleDiagonalSnippedREct':
              var width = 150;
              var height = 100;
              var cornerSize = 20; // Size of the snipped corners

              // Define path data for the rectangle with diagonally opposite corners snipped
              var pathData = [
                'M', 0, 0,                   // Move to the top-left corner
                'L', width - cornerSize, 0,  // Draw top edge minus snipped top-right corner
                'L', width, cornerSize,       // Draw diagonal line to snipped top-right corner
                'L', width, height,           // Draw right edge
                'L', cornerSize, height,      // Draw bottom edge minus snipped bottom-left corner
                'L', 0, height - cornerSize,  // Draw diagonal line to snipped bottom-left corner
                'Z'                           // Close the path
            ].join(' ');
            

              // Create Fabric.js path object
              newShape = new fabric.Path(pathData, {
                  left: 50,        // Adjust position as needed
                  top: 50,         // Adjust position as needed
                  fill: selectedColor,// Fill color
                  stroke: selectedStrokeColor, // Stroke color
                  strokeWidth: 2  // Stroke width
              });
              break;
            case 'snippedAndRoundedRect':
              var width = 150;
        var height = 100;
        var cornerRadius = 10; // Radius of the rounded corner
        var snippedSize = 20; // Size of the snipped corner

        // Path data for the custom shape
        var pathData = [
          'M', 0, cornerRadius,                           // Move to top-left starting point
          'A', cornerRadius, cornerRadius, 0, 0, 1, cornerRadius, 0,  // Draw top-left rounded corner
          'L', width - snippedSize, 0,                    // Draw top edge minus snipped corner
          'L', width, snippedSize,                        // Draw diagonal line to snipped corner
          'L', width, height,                             // Draw right edge
          'L', 0, height,                                 // Draw bottom edge
          'Z'                                             // Close the path
      ].join(' ');
      

        // Create Fabric.js path object
        newShape = new fabric.Path(pathData, {
            left: 50,   // Adjust position as needed
            top: 50,    // Adjust position as needed
            fill: selectedColor,  // Fill color
            stroke: selectedStrokeColor, // Stroke color
            strokeWidth: 2  // Stroke width
        });



              break;

            case '90Triangle':
              

                newShape = new fabric.Path('m 0 0 l 0 90 l 90 0 z', {
                    left: 100,            // Adjust position as needed
                    top: 100,             // Adjust position as needed
                    fill: selectedColor,       // Fill color
                    stroke: selectedStrokeColor,    // Stroke color
                    strokeWidth: 2,      // Stroke width
                });

              break;
            case 'parallelogram':
              var startX = 50;       
                var startY = 50;        
                var baseLength = 150;    
                var sideLength = 100;    
                var skewX = 50;         
                var skewY = 0;          

                // Calculate the path data for the parallelogram with skew
                var pathData = [
                    'M', startX + skewX, startY + skewY,                    
                    'L', startX + baseLength + skewX, startY + skewY,       
                    'L', startX + baseLength, startY + sideLength + skewY,  
                    'L', startX, startY + sideLength,                       
                    'Z'                                                     
                ].join(' ');

                newShape = new fabric.Path(pathData, {
                    left: 0,            
                    top: 0,             
                    fill: selectedColor,
                    stroke: selectedStrokeColor,    
                    strokeWidth: 2      
                });

              break;
          
              case 'hexagon':
    var centerX = 100;      // Center X coordinate
    var centerY = 100;      // Center Y coordinate
    var radius = 80;        // Radius of the hexagon
    var sides = 6;          // Number of sides (hexagon)

    // Function to create a regular polygon with n sides
    function createRegularPolygon(centerX, centerY, radius, sides) {
        var angle = (2 * Math.PI) / sides;
        var points = [];

        for (var i = 0; i < sides; i++) {
            var x = centerX + radius * Math.cos(i * angle);
            var y = centerY + radius * Math.sin(i * angle);
            points.push({ x: x, y: y });
        }

        var pathData = points.reduce(function (acc, point, index) {
            return acc + (index === 0 ? 'M' : 'L') + ' ' + point.x + ' ' + point.y + ' ';
        }, '') + 'Z';
        console.log("createRegularPolygon: ", pathData);
        return new fabric.Path(pathData, {
            fill: selectedColor,            // Fill color
            stroke: selectedStrokeColor,         // Stroke color
            strokeWidth: 2           // Stroke width
        });
    }

    newShape = createRegularPolygon(centerX, centerY, radius, sides);

    break;
    
    case 'heptagon':
    var centerX = 100;      // Center X coordinate
    var centerY = 100;      // Center Y coordinate
    var radius = 80;        // Radius of the heptagon
    var sides = 7;          // Number of sides (heptagon)

    // Function to create a regular polygon with n sides
    

    newShape = createRegularPolygon(centerX, centerY, radius, sides);

    break;
    case 'octagon':
      var centerX = 100;      // Center X coordinate
      var centerY = 100;      // Center Y coordinate
      var radius = 80;        // Radius of the octagon
      var sides = 8;          // Number of sides (octagon)
  
      
  
      newShape = createRegularPolygon(centerX, centerY, radius, sides);
  
      break;
      case 'nonagon':
        var centerX = 100;      // Center X coordinate
        var centerY = 100;      // Center Y coordinate
        var radius = 80;        // Radius of the nonagon
        var sides = 9;          // Number of sides (nonagon)
    
        // Function to create a regular polygon with n sides
        
    
        newShape = createRegularPolygon(centerX, centerY, radius, sides);
    
        break;
    
        case 'decagon':
          var centerX = 100;      // Center X coordinate
          var centerY = 100;      // Center Y coordinate
          var radius = 80;        // Radius of the decagon
          var sides = 10;         // Number of sides (decagon)
      
          newShape = createRegularPolygon(centerX, centerY, radius, sides);
      
          break;
          case 'hendecagon':
            var centerX = 100;      // Center X coordinate
            var centerY = 100;      // Center Y coordinate
            var radius = 80;        // Radius of the hendecagon
            var sides = 11;         // Number of sides (hendecagon)
            newShape = createRegularPolygon(centerX, centerY, radius, sides);
        
            break;
            case 'dodecagon':
              var centerX = 100;      // Center X coordinate
              var centerY = 100;      // Center Y coordinate
              var radius = 80;        // Radius of the dodecagon
              var sides = 12;         // Number of sides (dodecagon)
          
              newShape = createRegularPolygon(centerX, centerY, radius, sides);
          
              break;
              case 'pie':
    newShape = new fabric.Path('M256,0v256H0C0,397.4,114.6,512,256,512S512,397.4,512,256S397.4,0,256,0z', {
        fill: selectedColor,
        stroke: selectedStrokeColor,
        strokeWidth: 2, 
        left: 100,
        top: 100
    });

    break;
    case 'no':
    newShape = new fabric.Path('M213.333 960c0-167.36 56-321.707 149.44-446.4L1406.4 1557.227c-124.693 93.44-279.04 149.44-446.4 149.44-411.627 0-746.667-335.04-746.667-746.667m1493.334 0c0 167.36-56 321.707-149.44 446.4L513.6 362.773c124.693-93.44 279.04-149.44 446.4-149.44 411.627 0 746.667 335.04 746.667 746.667M960 0C429.76 0 0 429.76 0 960s429.76 960 960 960 960-429.76 960-960S1490.24 0 960 0', {
      fill: selectedColor,
      stroke: 'none',
        strokeWidth: 2,
        top:100,
        left:100
    });

    newShape.scaleX = 0.1;
    newShape.scaleY = 0.1;

    break;

  

    case 'teardrop':
        var pathData = [
          'M', 50, 0,                   // Move to the top-center
          'A', 50, 50, 0, 1, 1, 80, 80, // Draw an arc to the right
          'L', 0, 50,                // Draw to the bottom-right corner
          'L', 0, 50,                  // Draw to the bottom-left corner
          'Z'                           // Close the path
      ].join(' ');
      
      
      
      // Create Fabric.js path object for the teardrop shape
      newShape = new fabric.Path(pathData, {
          left: 100,       
          top: 100,        
          fill: selectedColor,    
          stroke: selectedStrokeColor, 
          strokeWidth: 2  
      });
      
      
        break;
        case 'chord':
          newShape = new fabric.Circle({
            left: 100,
            top: 100,
            fill: selectedColor,
            stroke: selectedStrokeColor,
            strokeWidth:2,
            radius: 25,
            class:'circle',
            startAngle: 0,
            endAngle: 270,
            selectable: true
          });

          break;

          case 'frame':
            var frameSize = 150;    // Size of the square frame
            var frameLeft = 50;     // X-coordinate of the top-left corner of the frame
            var frameTop = 50;      // Y-coordinate of the top-left corner of the frame
            var strokeWidth = 20;
            // Create Fabric.js rectangle for the square frame
            newShape = new fabric.Rect({
                left: frameLeft,
                top: frameTop,
                width: frameSize,
                height: frameSize,
                fill: selectedColor,  // Transparent fill
                stroke: selectedStrokeColor,       // Frame color
                strokeWidth: strokeWidth        // Frame width
            });

            break;
          case 'halfFrame':
            var lShapePathData = [
              'M', 0, 0,
              'L', 90, 0,
              'L', 75, 20,
              'L', 20, 20,
              'L', 20, 75,
              'L', 0, 90,
              'Z'
          ].join(' ');
          
          newShape = new fabric.Path(lShapePathData, {
              left: 50,
              top: 50,
              fill: selectedColor,
              stroke: selectedStrokeColor,
              strokeWidth:2
          });
          
            break;
          case 'lShape':
            var fShapePathData = [
              'M 0 0 L 30 0 L 30 60 L 60 60 L 60 90 L 0 90 Z '
          ].join(' ');
          
          newShape = new fabric.Path(fShapePathData, {
              left: 150,
              top: 50,
              fill: selectedColor,
              stroke: selectedStrokeColor,
              strokeWidth: 2
          });
          
            break;
          
          case 'smily':
            var facePathData = [
              'M', 50, 0,
              'A', 50, 50, 0, 1, 1, 50, 100,
              'A', 50, 50, 0, 1, 1, 50, 0,
              'M', 35, 40,
              'A', 5, 5, 0, 1, 1, 35, 50,
              'A', 5, 5, 0, 1, 1, 35, 40,
              'M', 65, 40,
              'A', 5, 5, 0, 1, 1, 65, 50,
              'A', 5, 5, 0, 1, 1, 65, 40,
              'M', 35, 65,
              'Q', 50, 80, 65, 65,
              'M',35,65,
              'Z'
          ].join(' ');
          
          newShape = new fabric.Path(facePathData, {
              left: 250,
              top: 50,
              fill: selectedColor,
              stroke: selectedStrokeColor,
              strokeWidth: 2
          });
          
            break;
            case 'sun':
              function createRay(x1, y1, x2, y2, x3, y3, angle) {
                var ray = new fabric.Path([
                    'M', x1, y1,
                    'L', x2, y2,
                    'L', x3, y3,
                    'Z'
                ].join(' '), {
                    fill: 'yellow',
                    stroke: selectedStrokeColor,
                    strokeWidth: 2,
                    originX: 'center',
                    originY: 'center'
                });
                ray.rotate(angle);
                return ray;
            }
            
            // Create rays
            var rays = [
                createRay(0, -25, 10, -50, -10, -50, 0),
                createRay(25, 0, 50, -10, 50, 10, 90),
                createRay(0, 25, 10, 50, -10, 50, 180),
                createRay(-25, 0, -50, -10, -50, 10, 270),
                createRay(15, -15, 35, -35, 5, -35, 45),
                createRay(15, 15, 35, 35, 5, 35, 135),
                createRay(-15, 15, -35, 35, -5, 35, 225),
                createRay(-15, -15, -35, -35, -5, -35, 315)
            ];
            
                      
              // Create the central circle
              var circle = new fabric.Circle({
                  radius: 25,
                  fill: 'yellow',
                  stroke: selectedStrokeColor,
                  strokeWidth: 2,
                  originX: 'center',
                  originY: 'center'
              });
          
              // Create the sun group with rays and circle
              newShape = new fabric.Group([...rays, circle], {
                  left: 100,  // Adjust position as needed
                  top: 100,   // Adjust position as needed
                  originX: 'center',
                  originY: 'center'
              });
          
          
              break;
          
            case 'bowArc':
              var arcPathData = [
                'M', 50, 100,
                'A', 50, 50, 0, 0, 1, 150, 100
            ].join(' ');
            
            newShape = new fabric.Path(arcPathData, {
                left: 50,
                top: 200,
                fill: selectedColor,
                stroke: 'b',
                strokeWidth: 10
            });
            
              break;
            case 'cloud':
              var cloudPathData = [
                'M', 60, 40,
                'C', 20, 10, 100, 10, 70, 40,
                // 'C', -20, 40, 150, 10, 70, -40,

                'C', 110, 30, 130, 80, 90, 80,
                'C', 110, 120, 30, 120, 50, 80,
                'C', 10, 100, 30, 60, 60, 40,
                'Z'
            ].join(' ');
            
            newShape = new fabric.Path(cloudPathData, {
                left: 150,
                top: 200,
                fill: selectedColor,
                stroke: selectedStrokeColor,
                strokeWidth: 2
            });
                        
              break;
            case 'solidFrame':
            var frameWidth = 200;  // Width of the frame
var frameHeight = 200; // Height of the frame
var frameThickness = 20; // Thickness of the frame

// Construct path data for the diagonally cut frame
var pathData = [
    // Outer rectangle
    'M', 0, 0,  // Move to top-left
    'L', frameWidth, 0,  // Line to top-right
    'L', frameWidth, frameHeight,  // Line to bottom-right
    'L', 0, frameHeight,  // Line to bottom-left
    'Z',  // Close the outer rectangle


    // Inner rectangle
    'M', frameThickness, frameThickness,  // Move to inner top-left
    'L', frameWidth - frameThickness, frameThickness,  // Line to inner top-right
    'L', frameWidth - frameThickness, frameHeight - frameThickness,  // Line to inner bottom-right
    'L', frameThickness, frameHeight - frameThickness,  // Line to inner bottom-left
    'Z',  // Close the inner rectangle

    // Diagonal cut line for inner rectangle
].join(' ');

// Create Fabric.js path object for the diagonally cut frame
newShape = new fabric.Path(pathData, {
    left: 50,  // X-coordinate of the top-left corner of the frame
    top: 50,  // Y-coordinate of the top-left corner of the frame
    fill: selectedColor,  // Transparent fill
    stroke: selectedStrokeColor,  // Frame color
    strokeWidth: 2  // Frame width
});

            break
            case 'doughnut':
        //       var circle = new fabric.Circle({
        //         radius: 40,
        //         left: 0,
        //         top: 0,
        //         angle: 0,
        //         startAngle: -0.3,
        //         endAngle: 0,
        //         stroke: selectedStrokeColor,
        //         strokeWidth: 25,
        //         fill: selectedColor
        // });
        
        var circle2 = new fabric.Circle({
                radius: 40,
                left: 0,
                top: 0,
                angle: 0,
                startAngle: 1,
                endAngle: 0,
                stroke: selectedStrokeColor,
                strokeWidth: 25,
                fill: selectedColor
        });
        
        // var circle3 = new fabric.Circle({
        //         radius: 40,
        //         left: 0,
        //         top: 0,
        //         angle: 0,
        //         startAngle: 0.3,
        //         endAngle: 0.7,
        //         stroke: '',
        //         strokeWidth: 25,
        //         fill: selectedColor
        // });
        newShape = new fabric.Group([ circle2]);
          
              break;
              case 'crescent':
                newShape = new fabric.Path('M12 22C17.5228 22 22 17.5228 22 12C22 11.5373 21.3065 11.4608 21.0672 11.8568C19.9289 13.7406 17.8615 15 15.5 15C11.9101 15 9 12.0899 9 8.5C9 6.13845 10.2594 4.07105 12.1432 2.93276C12.5392 2.69347 12.4627 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z', {
                    fill: selectedColor,
                    stroke: selectedStrokeColor,
                    strokeWidth: 2,
                    left: 100,
                    scaleX:4,
                    scaleY:4,
                    top: 100
                });
            
                break;
                case 'squareBrackets':
    var leftBracket = new fabric.Path('m 0 0 l 15 0 z l 0 60 z m 0 60 l 15 0', {
        fill: '#000000',
        stroke: '#000000',
        strokeWidth: 2
    });

    var rightBracket = new fabric.Path('m 0 0 l 15 0 z m 15 0 l 0 60 z m 0 60 l -15 0', {
        fill: '#000000',
        stroke: '#000000',
        strokeWidth: 2,
        left: 35  
    });

    // Group the shapes
    newShape = new fabric.Group([leftBracket, rightBracket], {
        left: 100,
        top: 100
    });

    break;
    case 'curlyBrackets':
      var leftBracket = new fabric.Path('m 30 0 l -15 0 z m -15 0 l 0 25 m 0 0 l -6 5 l 6 5 l 0 25 m 0 0 l 15 0',{
        fill: 'transparent',
        stroke: '#000000',
        strokeWidth: 2,
      })
      var rightBracket = new fabric.Path('m 0 0 l 15 0 z m 15 0 l 0 25 m 0 0 l 6 5 l -6 5 l 0 25 m 0 0 l -15 0',{
        fill: 'transparent',
        stroke: '#000000',
        strokeWidth: 2,
        left:35
      })

      newShape = new fabric.Group([leftBracket,rightBracket],{
        left:100,
        top:100
      })
      break;
// Case for Square with a folded corner
case 'folded':
    newShape = new fabric.Path('M0 0 L69 0 L69 34 L54 50 L0 50 Z M69 34 L53 34 M53 50 L53 34', {
        fill: selectedColor,
        stroke: selectedStrokeColor,
        strokeWidth:2,
        left: 50,
        top: 50
    });
    break;

// Case for Curved arrow



// Case for Hexagon


// Case for Ribbon banner
case 'ribbonBanner':
  const polygon1 = new fabric.Polygon([
    { x: 190.819, y: 58.156 },
    { x: 130.427, y: 57.08 },
    { x: 107.291, y: 5.256 },
    { x: 0.18, y: 151.208 },
    { x: 130.968, y: 150.863 }
], {
    fill: selectedColor,
    stroke: selectedStrokeColor,
    strokeWidth:2
});

const polygon2 = new fabric.Polygon([
    { x: 470.049, y: 271.126 },
    { x: 349.509, y: 271.555 },
    { x: 282.061, y: 360.071 },
    { x: 325.125, y: 367.377 },
    { x: 344.175, y: 466.035 }
], {
  fill: selectedColor,
  stroke: selectedStrokeColor,
  strokeWidth: 2
});

const polygon3 = new fabric.Polygon([
    { x: 62.343, y: 264.402 },
    { x: 62.53, y: 264.723 },
    { x: 66.824, y: 264.706 },
    { x: 345.623, y: 263.721 },
    { x: 350.568, y: 263.704 },
    { x: 355.512, y: 263.681 },
    { x: 466.338, y: 263.292 },
    { x: 471.292, y: 263.275 },
    { x: 468.798, y: 260.07 },
    { x: 463.862, y: 253.722 },
    { x: 389.315, y: 158.031 },
    { x: 135.254, y: 158.698 },
    { x: 130.578, y: 158.714 },
    { x: 125.897, y: 158.72 },
    { x: 0, y: 159.059 },
    { x: 60.111, y: 260.639 }
], {
  fill: selectedColor,
  stroke: selectedStrokeColor,
    strokeWidth: 2
});

// Group all parts together
newShape = new fabric.Group([polygon1, polygon2, polygon3]);
newShape.scaleX = 0.5;
newShape.scaleY = 0.5;


break;


case 'ribbonBanner2':
  var svgPathData = "M462.019,128.509l-51.392-17.131l57.152-95.253c2.389-3.989,1.92-9.045-1.131-12.523 c-3.072-3.477-8.043-4.544-12.288-2.731L113.027,150.205c-3.883,1.707-6.4,5.525-6.4,9.771v128c0,0.32,0.192,0.597,0.235,0.917 c0.107,1.131,0.363,2.197,0.832,3.243c0.213,0.469,0.363,0.917,0.64,1.365c0.661,1.088,1.536,2.005,2.603,2.816 c0.213,0.171,0.32,0.448,0.555,0.597c0.043,0.021,0.107,0.021,0.149,0.043c0.064,0.043,0.107,0.128,0.192,0.171l37.547,22.528 L49.048,363.538c-4.053,1.771-6.592,5.845-6.379,10.24c0.192,4.416,3.093,8.235,7.275,9.643l51.392,17.131l-57.152,95.253 c-2.389,3.989-1.92,9.045,1.131,12.523c2.069,2.347,4.992,3.627,8,3.627c1.451,0,2.901-0.299,4.267-0.896l341.333-149.333 c3.883-1.707,6.4-5.525,6.4-9.771v-128c0-0.32-0.192-0.597-0.235-0.917c-0.107-1.131-0.363-2.197-0.832-3.243 c-0.213-0.469-0.363-0.917-0.64-1.365c-0.661-1.088-1.536-2.005-2.603-2.816c-0.213-0.171-0.32-0.448-0.555-0.597 c-0.043-0.043-0.107-0.021-0.171-0.064c-0.064-0.043-0.107-0.128-0.171-0.171l-37.547-22.528l100.331-43.883 c4.053-1.771,6.592-5.845,6.379-10.24C469.101,133.736,466.2,129.896,462.019,128.509z M371.437,222.461l-198.101,86.656 l-32.832-19.691l198.101-86.656L371.437,222.461z";

  newShape = new fabric.Path(svgPathData, {
      left: 100,
      top: 100,
      fill: selectedColor, // Set the fill color
      stroke: '#000000', // Set the stroke color
      strokeWidth:2, // Set the stroke width
      selectable: true // Allow selection
  });
  

break;
case 'plus':
  var svgPathData = 'm 30 0 l 20 0 l 0 20 l 20 0 l 0 20 l -20 0 l 0 20 l -20 0 l 0 -20 l -20 0 l 0 -20 l 20 0 z ';
  newShape = new fabric.Path(svgPathData, {
    left: 100,
    top: 100,
    fill: selectedColor, // Set the fill color
    stroke: '#000000', // Set the stroke color
    strokeWidth:2, // Set the stroke width
    selectable: true // Allow selection
});
break;
case 'minus':
  var svgPathData = 'm 0 0 l 60 0 l 0 20 l -60 0 z ';
  newShape = new fabric.Path(svgPathData, {
    left: 100,
    top: 100,
    fill: selectedColor, // Set the fill color
    stroke: '#000000', // Set the stroke color
    strokeWidth:2, // Set the stroke width
    selectable: true // Allow selection
});
break;
case 'cross':
  var svgPathData = 'm 20 0 l -10 10 l 30 30 l -30 30 l 10 10 l 30 -30 l 30 30 l 10 -10 l -30 -30 l 30 -30 l -10 -10 l -30 30 z ';
  newShape = new fabric.Path(svgPathData, {
    left: 100,
    top: 100,
    fill: selectedColor, // Set the fill color
    stroke: '#000000', // Set the stroke color
    strokeWidth:2, // Set the stroke width
    selectable: true // Allow selection
});
break;

case 'devide':
  var svgPathData = 'm 0 40 l 0 15 l 60 0 l 0 -15 z m 32 -5 l -5 0 l 0 -5 l 5 0 z m 0 25 l -5 0 l 0 5 l 5 0 z  ';
  newShape = new fabric.Path(svgPathData, {
    left: 100,
    top: 100,
    fill: selectedColor, // Set the fill color
    stroke: '#000000', // Set the stroke color
    strokeWidth:2, // Set the stroke width
    selectable: true // Allow selection
});
break;
case 'equals':
  var svgPathData = 'm 0 0 L 80 0 L 80 15 L 0 15 Z M 0 25 L 80 25 L 80 40 L 0 40 Z';
  newShape = new fabric.Path(svgPathData, {
    left: 100,
    top: 100,
    fill: selectedColor, // Set the fill color
    stroke: '#000000', // Set the stroke color
    strokeWidth:2, // Set the stroke width
    selectable: true // Allow selection
});
break;



    

            
    case 'thunderbolt':
    newShape = new fabric.Path('M 30.384,1 ' +
        'L 20.384,18 ' +
        'L 26.384,18 ' +
        'L 18.384,34 ' +
        'L 24.384,34 ' +
        'L 17.384,54 ' +
        'L 37.384,28 ' +
        'L 32.384,28 ' +
        'L 41.384,11 ' +
        'L 37.384,11 ' +
        'L 43.384,1 ' +
        'z', {
            fill: selectedColor,
            stroke: selectedStrokeColor,
            strokeWidth: 2,
            left: 100,
            top: 100
        });

    break;

              case 'cross':
              var crossSize = 100; 
              var barThickness = 20; 
              
              var pathData = [
                  // Vertical bar 1 (left)
                  'M', crossSize / 2 - barThickness / 2, 0,
                  'L', crossSize / 2 + barThickness / 2, 0,
                  'L', crossSize / 2 + barThickness / 2, crossSize / 2 - barThickness / 2,
                  'L', crossSize / 2 - barThickness / 2, crossSize / 2 - barThickness / 2,
                  'Z',
                  // Vertical bar 2 (right)
                  'M', crossSize / 2 - barThickness / 2, crossSize / 2 - barThickness / 2,
                  'L', crossSize / 2 + barThickness / 2, crossSize / 2 - barThickness / 2,
                  'L', crossSize / 2 + barThickness / 2, crossSize,
                  'L', crossSize / 2 - barThickness / 2, crossSize,
                  'Z',
                  // Horizontal bar 1 (top)
                  'M', 0, crossSize / 2 - barThickness / 2,
                  'L', crossSize / 2 - barThickness / 2, crossSize / 2 - barThickness / 2,
                  'L', crossSize / 2 - barThickness / 2, crossSize / 2 + barThickness / 2,
                  'L', 0, crossSize / 2 + barThickness / 2,
                  'Z',
                  // Horizontal bar 2 (bottom)
                  'M', crossSize / 2 + barThickness / 2, crossSize / 2 - barThickness / 2,
                  'L', crossSize, crossSize / 2 - barThickness / 2,
                  'L', crossSize, crossSize / 2 + barThickness / 2,
                  'L', crossSize / 2 + barThickness / 2, crossSize / 2 + barThickness / 2,
                  'Z'
              ].join(' ');
          
              var cross = new fabric.Path(pathData, {
                  fill: selectedColor, // Fill color for the cross
                  stroke: selectedStrokeColor, // Stroke color for the border
                  strokeWidth:2
              });
          
              newShape = cross;
          
              break;
              case 'flowDecision':
                // Define the SVG path data for the polygon
                var svgPathData = 'M256,0 72.115,256 256,512 439.885,256 Z';
                
                // Create a Fabric.js polygon using the SVG path data
                newShape = new fabric.Path(svgPathData, {
                    left: 100,
                    top: 100,
                    fill: selectedColor,
                    stroke: selectedStrokeColor,
                    strokeWidth: 3,
                    selectable: true
                });
            
                // Adjust scale and position as needed
                newShape.scaleToWidth(150); // Scale to a specific width
                newShape.scaleToHeight(150); // Scale to a specific height
            
                break;
                case 'flowCollate':
                  // Define the SVG path data for the polygon
                  var svgPathData = 'M 0 0 L 60 0 L 0 70 L 60 70 Z';
                  
                  // Create a Fabric.js polygon using the SVG path data
                  newShape = new fabric.Path(svgPathData, {
                      left: 100,
                      top: 100,
                      fill: selectedColor,
                      stroke: selectedStrokeColor,
                      strokeWidth: 2,
                      selectable: true
                  });
              
              
                  break;
                
                case 'flowSort':
                // Define the SVG path data for the polygon
                var svgPathData = 'M 50 0 L 0 50 L 0 50 L 100 50 Z M 0 50 L 50 100 l 50 -50 Z';
                
                // Create a Fabric.js polygon using the SVG path data
                newShape = new fabric.Path(svgPathData, {
                    left: 100,
                    top: 100,
                    fill: selectedColor,
                    stroke: selectedStrokeColor,
                    strokeWidth: 2,
                    selectable: true
                });
            
            
                break;
                
                case 'flowMagSort':
                // Define the SVG path data for the polygon
                var svgPathData = 'M 100 20 A 100 25 0 1 0 -100 20 A 100 25 0 1 0 100 20 z L 100 70 A 100 25 0 0 1 -100 70 L -100 20 A 100 25 0 0 0 100 20 Z';
                
                // Create a Fabric.js polygon using the SVG path data
                newShape = new fabric.Path(svgPathData, {
                    left: 100,
                    top: 100,
                    fill: selectedColor,
                    stroke: selectedStrokeColor,
                    strokeWidth: 2,
                    selectable: true
                });
            
            
                break;
                
                case 'flowDAS':
                // Define the SVG path data for the polygon
                var svgPathData = 'M 0 100 A 25 100 0 1 0 0 -100 A 25 100 0 1 0 0 100 M 0 100 L -100 100 A 25 100 0 1 1 -100 -100 L 0 -100 M 0 100 A 25 100 0 1 1 0 -100';
                
                // Create a Fabric.js polygon using the SVG path data
                newShape = new fabric.Path(svgPathData, {
                    left: 100,
                    top: 100,
                    fill: selectedColor,
                    stroke: selectedStrokeColor,
                    strokeWidth: 2,
                    selectable: true
                });
            
            
                break;
                
                case 'flowPreDefined':
                  newShape = new fabric.Path('m 0 0 l 40 0 l 0 40 l -40 0 z m 8 0 l 0 40 m 24 0 l 0 -40', {
                    left: 100,
                    top: 100,
                    fill: selectedColor,
                    stroke: selectedStrokeColor,
                    strokeWidth:2,
                    selectable: true
                });
                  break;
                case 'flowInternalStorage':
                  newShape=new fabric.Path('m 0 0 l 40 0 l 0 40 l -40 0 z m 8 0 l 0 40 m 32 -32 l -40 0',{
                    left: 100,
                    top: 100,
                    fill: selectedColor,
                    stroke: selectedStrokeColor,
                    strokeWidth:2,
                    selectable: true
                  })
                break;
                case 'flowDoc':
                  newShape = new fabric.Path('m 0 0 l 60 0 l 0 50 c -15 -6 -24 4 -25 4 c -15 11 -22 15 -35 5 Z',{
                    left: 100,
                    top: 100,
                    fill: selectedColor,
                    stroke: selectedStrokeColor,
                    strokeWidth:2,
                    selectable: true

                  })
                  break;
                  case 'flowMultiDoc':
                  newShape = new fabric.Path('m 0 0 l 60 0 l 0 50 c -15 -6 -24 4 -25 4 c -15 11 -22 15 -35 5 Z m 5 0 l 0 -5 l 60 0 l 0 50 l -5 0 l 0 -45 m -50 -5 l 0 -5 l 60 0l 0 50 l -5 0 l 0 -45 z',{
                    left: 100,
                    top: 100,
                    fill: selectedColor,
                    stroke: selectedStrokeColor,
                    strokeWidth:2,
                    selectable: true

                  })
                  break;
                  
                  case 'flowTerminator':
                  newShape = new fabric.Path('m 15 0 l 40 0 a 1 1 0 0 1 0 20 l -40 0 a 1 1 0 0 1 0 -20',{
                    left: 100,
                    top: 100,
                    fill: selectedColor,
                    stroke: selectedStrokeColor,
                    strokeWidth:2,
                    selectable: true

                  })
                  break;
                  case 'flowPreparation':
                    var centerX = 100;      // Center X coordinate
                    var centerY = 100;      // Center Y coordinate
                    var radius = 80;        // Radius of the hexagon
                    var sides = 6;          // Number of sides (hexagon)             
              
                    
                    newShape = new fabric.Path('m 30 0 l 40 0 l 10 10 l -10 10 l -40 0 l -10 -10 l 10 -10', {
                      left: centerX - radius,  // Adjust position as needed
                      top: centerY - radius,   // Adjust position as needed
                      fill: selectedColor,            // Fill color
                      stroke: selectedStrokeColor, 
                      width:"200px",        // Stroke color
                      strokeWidth:2           // Stroke width
                  });
                  newShape.scaleX = 1.5;
                  newShape.scaleY = 1.5;

                    
                    break;
                    case 'flowOffPConnector':
                  newShape = new fabric.Path('m 0 0 l 50 0 l 0 70 l -25 24 l -25 -24 z ',{
                    left: 100,
                    top: 100,
                    fill: selectedColor,
                    stroke: selectedStrokeColor,
                    strokeWidth:2,
                    selectable: true

                  })
                  break;
                  case 'flowCard':
                    newShape = new fabric.Path('m 20 0 l 50 0 l 0 50 l -60 0 l 0 -40 z ',{
                      left: 100,
                      top: 100,
                      fill: selectedColor,
                      stroke: selectedStrokeColor,
                      strokeWidth:2,
                      selectable: true
  
                    })
                    break;
                    case 'flowPunchedTape':
                    newShape = new fabric.Path('m 20 20 c 15 8 25 -2 30 -5 c 18 -14 29 -6 30 -6 l 0 60 c -18 -14 -29 6 -30 6 c -7 9 -25 10 -30 5 z  ',{
                      left: 100,
                      top: 100,
                      fill: selectedColor,
                      stroke: selectedStrokeColor,
                      strokeWidth:2,
                      selectable: true
  
                    })
                    break;
                    case 'flowSumming':
                      newShape = new fabric.Path('m 50 0 a 10 10 0 0 0 0 50 a 10 10 0 0 0 0 -50 l 0 50 m 25 -25 l -50 0 z',{
                        left: 100,
                        top: 100,
                        fill: selectedColor,
                        stroke: selectedStrokeColor,
                        strokeWidth:2,
                        selectable: true
    
                      })
                      break;
                  case 'flowSeqStorage':
                    const url = 'm 50 0 a 1 1 0 0 0 0 50 m 0 -50 a 1 1 0 0 1 0 40 l 15 0 l 0 10 l -15 0';
                    newShape = new fabric.Path(url,{
                      left: 100,
                      top: 100,
                      fill: selectedColor,
                      stroke: selectedStrokeColor,
                      strokeWidth:2,
                      selectable: true
  
                    })
                    break;
                    case 'flowDelay':
                    var svgUrl = 'm 0 0 l 0 40 l 25 0 a 1 1 0 0 0 0 -40 z ';
                    newShape = new fabric.Path(svgUrl,{
                      left: 100,
                      top: 100,
                      fill: selectedColor,
                      stroke: selectedStrokeColor,
                      strokeWidth:2,
                      selectable: true
  
                    })
                    break;
                    case 'flowStoredData':
                    var svgUrl = 'm 0 0 a 100 70 0 0 0 0 70 l 80 0 a 100 70 0 0 1 0 -70 z';
                    newShape = new fabric.Path(svgUrl,{
                      left: 100,
                      top: 100,
                      fill: selectedColor,
                      stroke: selectedStrokeColor,
                      strokeWidth:2,
                      selectable: true
  
                    })
                    break;
                    case 'flowDisplay':
                      var svgPathData = "m 30 0 l 15 0 a 1 1 0 0 1 0 40 l -15 0 l -15 -20 l 15 -20 z";

                      newShape = new fabric.Path(svgPathData, {
                          left: 100,
                          top: 100,
                          fill: selectedColor, // Set the fill color
                          stroke: '#000000', // Set the stroke color
                          strokeWidth:2, // Set the stroke width
                          selectable: true // Allow selection
                      });
                    break;
                    case 'flowSummingJunction':
                      var svgPathData = "M 50, 0 A 50, 50, 0, 1, 0, -50, 0 A 50, 50, 0, 1, 0, 50, 0 Z M -35.36, -35.36 L 35.36, 35.36 M -35.36, 35.36 L 35.36, -35.36";

                      newShape = new fabric.Path(svgPathData, {
                          left: 100,
                          top: 100,
                          fill: selectedColor, // Set the fill color
                          stroke: '#000000', // Set the stroke color
                          strokeWidth:2, // Set the stroke width
                          selectable: true // Allow selection
                      });
                    break;
                    case 'flowManualOp':
                      var svgPathData = "M 0 0 L 80 0 L 60 50 L 20 50 Z";

                      newShape = new fabric.Path(svgPathData, {
                          left: 100,
                          top: 100,
                          fill: selectedColor, // Set the fill color
                          stroke: '#000000', // Set the stroke color
                          strokeWidth:2, // Set the stroke width
                          selectable: true // Allow selection
                      });
                    break;
                    
                    case 'flowManualInp':
                      var svgPathData = "M 100 0 L 100 70 L 0 70 L 0 15 Z";

                      newShape = new fabric.Path(svgPathData, {
                          left: 100,
                          top: 100,
                          fill: selectedColor, // Set the fill color
                          stroke: '#000000', // Set the stroke color
                          strokeWidth:2, // Set the stroke width
                          selectable: true // Allow selection
                      });
                    break;
                    
                    case 'roundSpeech':
                      var svgPathData = "M257.135,19.179C103.967,19.179,0,97.273,0,218.763c0,74.744,31.075,134.641,91.108,173.176c4.004,2.572,8.728,2.962,6.955,10.365c-7.16,29.935-19.608,83.276-19.608,83.276c-0.527,2.26,0.321,4.618,2.162,6.03c1.84,1.402,4.334,1.607,6.38,0.507c0,0,87.864-52.066,99.583-58.573c27.333-15.625,50.878-18.654,68.558-18.654C376.619,414.89,512,366.282,512,217.458C512,102.036,418.974,19.179,257.135,19.179z";

                      newShape = new fabric.Path(svgPathData, {
                          left: 100,
                          top: 100,
                          fill: selectedColor, // Set the fill color
                          stroke: '#000000', // Set the stroke color
                          strokeWidth:2, // Set the stroke width
                          selectable: true // Allow selection
                      });
                    break;

                    
            
      default:
      console.error('Unknown shape type:', shape);
      return;
  }
  console.log("canvas in handleDrawingMode : ",canvas)
  console.log("handleDrawingMode : newShape:",newShape)
  var centerX = newShape.left + newShape.width / 2;
  var centerY = newShape.top + newShape.height / 2;

  newShape.set({
    originX: 'center',
    originY: 'center',
  });

  newShape.set({
    left: centerX,
    top: centerY,
  });

  canvas.isDrawingMode = false;
  canvas.add(newShape);
  // canvas.setActiveObject(newShape);
};



// Handle free drawing click
export const handleFreeDrawingClick = (canvas) => {
  // setIsDrawingSquare(false);
  // setIsDrawingTriangle(false);
  // setIsDrawingCircle(false);
  console.log("canvas in handleFreeDrawingClick: ",canvas)
  if (canvas) {
    canvas.isDrawingMode = true;
    canvas.selectable = false;
    canvas.defaultCursor = 'default';
  }
};
// Updated handlePathCreated function with point editing tracking
export const handlePathCreated = (canvas, path, selectedColor) => {
  if (!canvas) return;
  if (!path) return;

  const radius = 10;
  const pattern = new fabric.Circle({
    left: 0,
    top: 0,
    fill: selectedColor,
    stroke: 'black',
    strokeWidth:2,
    radius: radius,
    selectable: false
  });
  const currentPath = path.path ? path.path : path;
  const pathLength = currentPath.length;
  const interval = 2;
  const patternClones = [];

  currentPath.forEach((segment, i) => {
    if (i % interval === 0) {
      const patternClone = fabric.util.object.clone(pattern);
      patternClone.left = segment[1] - radius;
      patternClone.top = segment[2] - radius;
      patternClones.push(patternClone);
    }
  });

  if (canvas) {
    patternClones.forEach(patternClone => {
      canvas.add(patternClone);
    });
    canvas.remove(path);
  }

  canvas.on('object:modified', (e) => {
    const target = e.target;
    if (target && target.type === 'path') {
      currentPath.forEach((segment, i) => {
        if (i % interval === 0) {
          const patternClone = patternClones[i / interval];
          if (patternClone) {
            patternClone.left = segment[1] - radius;
            patternClone.top = segment[2] - radius;
          }
        }
      });

      canvas.renderAll();
    }
  });

  canvas.renderAll();
};





// Handle add circle
export const handleAddCircle = (canvas, selectedColor) => {
  // setIsDrawingSquare(false);
  // setIsDrawingCircle(true);
  // setIsDrawingTriangle(false);

  if (canvas) {
    const circle = new fabric.Circle({
      left: 100,
      top: 100,
      fill: selectedColor,
      stroke: 'black',
      strokeWidth:2,
      radius: 25,
      selectable: true
    });
    canvas.isDrawingMode = false;

    canvas.add(circle);
    canvas.setActiveObject(circle);
  }
};

// Handle add triangle
export const handleAddTriangle = (canvas, selectedColor) => {
  // setIsDrawingSquare(false);
  // setIsDrawingCircle(false);
  // setIsDrawingTriangle(true);

  if (canvas) {
    const triangle = new fabric.Triangle({
      left: 100,
      top: 100,
      fill: selectedColor,
      stroke: 'black',
      strokeWidth:2,
      width: 50,
      height: 50,
      selectable: true
    });
    canvas.add(triangle);
    canvas.isDrawingMode = false;

    canvas.setActiveObject(triangle);
  }
};

// Handle upload SVG
export const handleUploadSVG = (event, canvas) => {
  const file = event?.target?.files[0];
  if (!file) return;

  const reader = new FileReader();
  reader.onload = function (e) {
    const svgString = e.target.result;

    fabric.loadSVGFromString(svgString, (objects, options) => {
      if (!objects || objects.length === 0) {
        console.error('No objects were parsed from the SVG string.');
        return;
      }
      
      try {
        const svgObjects = fabric.util.groupSVGElements(objects, options);
        svgObjects.set({
          scaleX: svgObjects.scaleX * 0.5,
          scaleY: svgObjects.scaleY * 0.5,
        });

        canvas.add(svgObjects).renderAll();
        console.log("svgObjects: ", svgObjects)
      } catch (error) {
        console.error('Error adding SVG objects to the canvas:', error);
      }
    });
  };

  reader.onerror = function (error) {
    console.error('Error reading SVG file:', error);
  };

  reader.readAsText(file);
};

// Handle set color
export const handleSetColor = (e, setSelectedColor) => {
  
    setSelectedColor(e)
  
};
